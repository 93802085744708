export const dataValues = [
  { id: 0, name: 'BOOLEAN' },
  { id: 1, name: 'INTEGER' },
  { id: 2, name: 'DOUBLE' },
  { id: 3, name: 'STRING' },
  { id: 4, name: 'ENUM' },
  { id: 5, name: 'LIST' },
];

export const dataValuesMap = new Map([
  ['BOOLEAN', 0],
  ['INTEGER', 1],
  ['DOUBLE', 2],
  ['STRING', 3],
  ['ENUM', 4],
  ['LIST', 5],
]);

export const actionTypes = {
  edit: 'edit',
  add: 'add',
  delete: 'delete',
};
