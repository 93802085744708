import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { shape, string, func, bool, number } from 'prop-types';
import {
  TextField,
  Button,
  Checkbox,
  Autocomplete,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

import ROUTES from 'routes/routesPaths';
import SERVICES from 'constants/services';
import { getPotentialVegetation, getEstablishment } from 'api/gets';
import styles from './styles.module.scss';

const PaddockForm = ({ formValues, setFormValues, onSubmit, isLoading, establishmentId }) => {
  const navigate = useNavigate();

  const onChange = useCallback(
    (inputKey, e) => {
      setFormValues({ ...formValues, [inputKey]: e.target.value });
    },
    [formValues, setFormValues]
  );

  const { data: establishmentData, isLoading: isLoadingEstablishment } = useQuery(
    [SERVICES.establishments, establishmentId],
    () => getEstablishment(establishmentId)
  );

  const indexId = establishmentData?.indexId;

  const { data: potentialVegetation, isLoading: isLoadingPotentialVegetation } = useQuery(
    [SERVICES.getPotentialVegetation],
    () => getPotentialVegetation(indexId),
    {
      enabled: !!indexId,
    }
  );

  const potentialVegetationOptions = useMemo(
    () =>
      potentialVegetation?.valuesList?.map(({ id, name, value }) => ({
        id,
        label: `${value} - ${name}`,
      })),
    [potentialVegetation]
  );

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <TextField
        id="name"
        label="Nombre"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        className={styles.input}
        required
        placeholder="Nombre"
        value={formValues.name || ''}
        onChange={(e) => onChange('name', e)}
        disabled={isLoading}
      />
      <TextField
        id="description"
        label="Descripción"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        className={styles.input}
        required
        placeholder="Descripción"
        value={formValues.description || ''}
        onChange={(e) => onChange('description', e)}
        disabled={isLoading}
      />
      <TextField
        id="surface"
        label="Superficie"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        className={styles.input}
        required
        placeholder="Superficie"
        value={formValues.surface || ''}
        onChange={(e) => onChange('surface', e)}
        disabled={isLoading}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">HA</InputAdornment>,
          classes: {
            input: styles.input,
          },
        }}
      />
      <div className={styles.labelContainer}>
        <div className={styles.label}>
          <span className="h4-bold-14">Vegetación potencial</span>
        </div>
        <Autocomplete
          id="combo-box-demo"
          loading={isLoadingPotentialVegetation}
          value={formValues.potentialVeg}
          getOptionLabel={(option) => option?.label || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, newValue) => {
            setFormValues({ ...formValues, potentialVeg: newValue });
          }}
          disabled={isLoading}
          options={potentialVegetationOptions || []}
          sx={{ maxWidth: 500, width: '100%' }}
          className={styles.inputSelect}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                className={styles.inputSelect}
                value={params?.id}
                placeholder="Vegetación potencial"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoadingPotentialVegetation || isLoadingEstablishment ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            );
          }}
        />
      </div>
      <div className={styles.labelContainer}>
        <span className="h4-bold-14">Zona Riparia</span>
        <Checkbox
          id="hasZr"
          label="Zona Riparia"
          disabled={isLoading}
          checked={formValues.hasZr}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          onClick={(e) => setFormValues({ ...formValues, hasZr: !formValues.hasZr })}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          variant="contained"
          size="large"
          disabled={isLoading}
          className={styles.button}
          onClick={() => navigate(`${ROUTES.establishments}/${establishmentId}${ROUTES.edit}`)}
        >
          <span className="h3-bold-18 white">Volver</span>
        </Button>

        <LoadingButton
          variant="contained"
          size="large"
          className={styles.button}
          loading={isLoading}
          type="submit"
        >
          {!isLoading && <span className="h3-bold-18 white">Confirmar</span>}
        </LoadingButton>
      </div>
    </form>
  );
};

PaddockForm.propTypes = {
  formValues: shape({
    name: string,
    description: string,
    observation: string,
  }).isRequired,
  setFormValues: func.isRequired,
  onSubmit: func.isRequired,
  isLoading: bool,
  establishmentId: number,
};

export default PaddockForm;
