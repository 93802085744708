import { useState } from 'react';
import { string, array, bool, number } from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Fab, IconButton, Button } from '@mui/material';
import EditIcon from '@iconify/icons-eva/edit-2-fill';
import { Icon } from '@iconify/react';

import Table from 'components/table';
import EnumValueModal from 'components/enum-value-modal';
import { actionTypes } from 'constants';

import colors from 'styles/common/constants.module.scss';
import styles from './styles.module.scss';

const EnumValuesTable = ({ rows, isLoading, indexId, enumId, tableClassName, ...props }) => {
  const [actionType, setActionType] = useState(null);
  const [openEnumValueModal, setOpenEnumValueModal] = useState(false);
  const [customValueData, setCustomValueData] = useState({});

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
    },
    {
      field: 'name',
      headerName: 'Nombre',
      headerAlign: 'left',
      flex: 3,
      align: 'left',
    },
    {
      field: 'description',
      headerName: 'Descripción',
      headerAlign: 'left',
      align: 'left',
      flex: 4,
    },
    {
      field: 'value',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'delete',
      headerName: 'Borrar',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              setCustomValueData(params.row);
              setOpenEnumValueModal(true);
              setActionType(actionTypes.delete);
            }}
          >
            <DeleteIcon sx={{ fontSize: 30, color: colors?.red || 'red' }} />
          </IconButton>
        );
      },
    },
    {
      field: 'edit',
      headerName: 'Editar',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        return (
          <div className={styles.buttonContainer}>
            <Button
              variant="outlined"
              onClick={() => {
                setCustomValueData(params.row);
                setOpenEnumValueModal(true);
                setActionType(actionTypes.edit);
              }}
            >
              <Icon icon={EditIcon} width={23} height={23} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.row}>
        <span className={`h2-bold-24 ${styles.tableTitle}`}>Valores:</span>
        <Fab
          color="primary"
          aria-label="add"
          className={styles.addButton}
          onClick={() => {
            setCustomValueData();
            setOpenEnumValueModal(true);
            setActionType(actionTypes.add);
          }}
        >
          <AddIcon label="agregar" sx={{ fontSize: 35 }}>
            Agregar
          </AddIcon>
        </Fab>
      </div>
      <Table
        rows={rows}
        columns={columns}
        cardClassName={tableClassName}
        loading={isLoading}
        {...props}
      />
      <EnumValueModal
        indexId={indexId}
        enumId={enumId}
        open={openEnumValueModal}
        setOpen={setOpenEnumValueModal}
        formValues={customValueData}
        setFormValues={setCustomValueData}
        actionType={actionType}
      />
    </>
  );
};

EnumValuesTable.propTypes = {
  rows: array,
  isLoading: bool,
  indexId: number,
  entityId: number,
  tableClassName: string,
};

export default EnumValuesTable;
