import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { ReactComponent as GoogleIcon } from 'assets/google.svg';
import { useSession } from 'hooks';

import toastSyles from 'styles/common/toast.module.scss';
import styles from './styles.module.scss';

const LoginForm = () => {
  const { signIn } = useSession();

  console.log('Client ID:', process.env.REACT_APP_GOOGLE_CLIENT_ID);

  const onSuccess = (credentialResponse) => {
    console.log('Google Login Success Response:', credentialResponse);
    const tokenId = credentialResponse.credential;
    const decoded = jwtDecode(tokenId);
    console.log('Decoded Token:', decoded);
    
    const { name, email, picture: imageUrl } = decoded;

    signIn(name, email, imageUrl, tokenId);
    console.log('Login Success:', decoded);
  };

  const onFailure = (error) => {
    console.error('Google Login Error:', error);
    toast('Error: Error al hacer login', { type: 'error', className: toastSyles.error });
    console.log('Error al hacer login 2');
  };

  return (
    
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <GoogleLogin
          onSuccess={onSuccess}
          onError={onFailure}
          uxMode="redirect"
          useOneTap
          scope="openid email profile"
          render={({ onClick, disabled }) => (
          <Button
              fullWidth
              variant="contained"
              onClick={() => {
                console.log('Button clicked');
                onClick();
              }}
              disabled={disabled}
              className={styles.button}
            >
              <GoogleIcon width={28} height={28} className={styles.googleIcon} alt="Google Icon" />
              Acceder con Google 2
            </Button>
          )}
        />
      </div>
    </div>
  );
};

export default LoginForm;
