// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_iconContainer__TokSn {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 1rem;
}

.styles_item__JpHiP {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.styles_leafIconContainer__U-vZL {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 1rem;
  opacity: 0.6;
}

.styles_treeContainer__Ih39U {
  height: 100%;
  width: 100%;
}

.styles_editButton__aik8n {
  height: 3rem;
  margin-left: 1.5rem;
  min-width: 4rem;
  padding: 0;
  width: 5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/common/custom-tree-view/styles.module.scss","webpack://./src/styles/common/mixins.scss"],"names":[],"mappings":"AAGA;ECFE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;EDCA,kBAAA;AAYF;;AATA;EACE,mBAAA;EACA,aAAA;EACA,SAAA;AAYF;;AARA;ECdE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;EDaA,kBAAA;EACA,YAAA;AAcF;;AAXA;EACE,YAAA;EACA,WAAA;AAcF;;AAXA;EACE,YAAA;EACA,mBAAA;EACA,eAAA;EACA,UAAA;EACA,WAAA;AAcF","sourcesContent":["@import 'styles/common/mixins';\n@import 'styles/common/constants.module';\n\n.iconContainer {\n  @include flex-center();\n  margin-right: 1rem;\n}\n\n.item{\n  align-items: center;\n  display: flex;\n  gap: 1rem;\n}\n\n\n.leafIconContainer {\n  @include flex-center();\n  margin-right: 1rem;\n  opacity: 0.6;\n}\n\n.treeContainer {\n  height: 100%;\n  width: 100%;\n}\n\n.editButton{\n  height: 3rem;\n  margin-left: 1.5rem;\n  min-width: 4rem;\n  padding: 0;\n  width: 5rem;\n}\n","@mixin flex-center($flex-direction: row) {\n  align-items: center;\n  display: flex;\n  flex-direction: $flex-direction;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"iconContainer": `styles_iconContainer__TokSn`,
	"item": `styles_item__JpHiP`,
	"leafIconContainer": `styles_leafIconContainer__U-vZL`,
	"treeContainer": `styles_treeContainer__Ih39U`,
	"editButton": `styles_editButton__aik8n`
};
export default ___CSS_LOADER_EXPORT___;
