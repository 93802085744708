import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import PageTitle from 'components/common/page-title';
import EstablishmentForm from 'components/establishment-form';
import SERVICES from 'constants/services';
import { getEstablishment } from 'api/gets';

import styles from './styles.module.scss';

const DisplayEstablishment = () => {
  const { establishmentId } = useParams();

  const [formValues, setFormValues] = useState({
    name: '',
    producer: '',
    department: '',
    location: '',
    paddocks: [],
  });

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const { isLoading } = useQuery(
    [SERVICES.establishments, establishmentId],
    () => getEstablishment(establishmentId),
    {
      onSuccess: (data) => setFormValues(data),
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <PageTitle
          text={`Ver Establecimiento : ${formValues?.name}`}
          isCenterText={true}
          showHr={false}
        />
      </div>

      <div className={styles.content}>
        <EstablishmentForm
          formValues={formValues}
          setFormValues={setFormValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
          enabled={false}
          establishmentId={establishmentId}
        />
      </div>
    </div>
  );
};

export default DisplayEstablishment;
