import { useCallback } from 'react';
import { shape, string, func, bool } from 'prop-types';
import { TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'routes/routesPaths';

import styles from './styles.module.scss';

const IndexForm = ({ formValues, setFormValues, onSubmit, isLoading }) => {
  const navigate = useNavigate();

  const onChange = useCallback(
    (inputKey, e) => {
      setFormValues({ ...formValues, [inputKey]: e.target.value });
    },
    [formValues, setFormValues]
  );

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <TextField
        id="name"
        label="Nombre"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        className={styles.input}
        required
        placeholder="Nombre"
        value={formValues.name || ''}
        onChange={(e) => onChange('name', e)}
        disabled={isLoading}
      />
      <TextField
        id="description"
        label="Descripción"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        className={styles.input}
        required
        placeholder="Descripción"
        value={formValues.description || ''}
        onChange={(e) => onChange('description', e)}
        disabled={isLoading}
      />
      <TextField
        id="observation"
        label="Observaciones"
        variant="outlined"
        multiline
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: {
            input: styles.inputLarge,
          },
        }}
        className={styles.inputLarge}
        rows={4}
        placeholder="Observaciones"
        value={formValues.observation || ''}
        onChange={(e) => onChange('observation', e)}
        disabled={isLoading}
      />
      <div className={styles.buttons}>
        <Button
          variant="contained"
          size="large"
          disabled={isLoading}
          className={styles.button}
          onClick={() => navigate(ROUTES.backoffice)}
        >
          <span className="h3-bold-18 white">Volver</span>
        </Button>

        <LoadingButton
          variant="contained"
          size="large"
          className={styles.button}
          loading={isLoading}
          type="submit"
        >
          {!isLoading && <span className="h3-bold-18 white">Confirmar</span>}
        </LoadingButton>
      </div>
    </form>
  );
};

IndexForm.propTypes = {
  formValues: shape({
    name: string,
    description: string,
    observation: string,
  }).isRequired,
  setFormValues: func.isRequired,
  onSubmit: func.isRequired,
  isLoading: bool,
};

export default IndexForm;
