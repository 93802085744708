import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress, Tooltip } from '@mui/material';
import PlantIcon from '@iconify-icons/la/pagelines';

import PageTitle from 'components/common/page-title';
import EntityForm from 'components/backoffice/entity-form';
import ROUTES from 'routes/routesPaths';
import SERVICES from 'constants/services';
import { getIndex } from 'api/gets';
import { createIndexEntity } from 'api/posts';

import toastSyles from 'styles/common/toast.module.scss';
import styles from './styles.module.scss';

const CreateEntity = () => {
  const [formValues, setFormValues] = useState({
    shortName: '',
    name: '',
    father: {},
    formula: '',
  });

  const { indexId } = useParams();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: indexData, isLoading: isLoadingIndex } = useQuery([SERVICES.indexes, indexId], () =>
    getIndex(indexId)
  );

  const { mutate: doCreateEntity, isLoading: isLoadingCreateEntity } = useMutation(
    () =>
      createIndexEntity(indexId, {
        name: formValues?.name,
        shortName: formValues?.shortName,
        formula: formValues?.formula,
        fatherId: formValues?.father?.id || null,
      }),
    {
      onError: (error) => {
        toast(`Error: ${error?.message || 'Something went wrong'}`, {
          type: 'error',
          className: toastSyles.error,
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries([SERVICES.indexes, indexId]);
        toast('Entidad creada', {
          type: 'success',
          className: toastSyles.success,
          onClose: () =>
            navigate(`${ROUTES.indexDetail}/${indexId}${ROUTES.editEntity}/${data.id}`),
        });
      },
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    doCreateEntity();
  };

  return (
    <div className={styles.container}>
      {isLoadingIndex ? (
        <CircularProgress color="success" />
      ) : (
        <Tooltip
          title={<span className="btn-semibold-12 gray">Ir a detalle del indice</span>}
          classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
          placement="right"
          arrow
        >
          <div className={styles.titleWrapper}>
            <PageTitle
              text={indexData?.name}
              icon={PlantIcon}
              iconClassName={styles.icon}
              isCenterText={true}
              showHr={false}
              titleContainerClassName={styles.titleContainer}
              onClick={() => navigate(`${ROUTES.indexDetail}/${indexId}`)}
            />
          </div>
        </Tooltip>
      )}

      <div className={styles.subTitleWrapper}>
        <PageTitle text="Crear entidad" isCenterText={true} />
      </div>

      <div className={styles.content}>
        <EntityForm
          formValues={formValues}
          setFormValues={setFormValues}
          onSubmit={onSubmit}
          isLoading={isLoadingCreateEntity}
          onLeftClick={() => navigate(-1)}
          showTables={false}
          isShowDataTable={false}
          indexId={indexId}
        />
      </div>
    </div>
  );
};

export default CreateEntity;
