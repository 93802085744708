import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import ListIcon from '@iconify/icons-eva/list-fill';
import ZoomInIcon from '@iconify/icons-eva/maximize-outline';
import { MenuItem, Menu, Button } from '@mui/material';
import { string, array, bool } from 'prop-types';
import EditIcon from '@iconify/icons-eva/edit-2-fill';

import ROUTES from 'routes/routesPaths';
import Table from 'components/table';

import styles from './styles.module.scss';

const CustomEnumsTable = ({ rows, isLoading, indexId, entityId, tableClassName, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const openValuesList = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleValuesClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setActiveItem(id);
  };
  const handleValuesClose = () => {
    setAnchorEl(null);
    setActiveItem(null);
  };

  const columns = [
    {
      field: 'edit',
      headerName: 'EDITAR',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        return (
          <div className={styles.buttonContainer}>
            <Button
              variant="outlined"
              onClick={() =>
                navigate(
                  `${ROUTES.indexDetail}/${indexId}${ROUTES.enums}/${params.row.id}${ROUTES.edit}`
                )
              }
            >
              <Icon icon={EditIcon} width={23} height={23} />
            </Button>
          </div>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Nombre',
      headerAlign: 'left',
      flex: 2,
      align: 'left',
      renderCell: (params) => {
        return (
          <div className={styles.tableRow}>
            <Icon icon={ListIcon} width={23} height={23} />
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Descripción',
      headerAlign: 'left',
      flex: 3,
      align: 'left',
    },
    {
      field: 'valuesList',
      headerName: 'Valores',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const row = params.row;

        const values = params.value;
        return (
          <>
            <Button
              id="basic-button"
              aria-controls={openValuesList && activeItem === row.id ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openValuesList && activeItem === row.id ? 'true' : undefined}
              onClick={(event) => handleValuesClick(event, row.id)}
              className={styles.valuesList}
              variant="outlined"
            >
              <Icon icon={ZoomInIcon} width={23} height={23} />
              Ver valores
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openValuesList && activeItem === row.id}
              onClose={handleValuesClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {values?.map(({ id, name, value }) => (
                <MenuItem key={id} value={value} onClick={handleValuesClose}>
                  {name}
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      },
    },
  ];

  return (
    <Table
      rows={rows}
      columns={columns}
      cardClassName={tableClassName}
      loading={isLoading}
      {...props}
    />
  );
};

CustomEnumsTable.propTypes = {
  rows: array,
  isLoading: bool,
  indexId: string,
  entityId: string,
  tableClassName: string,
};

export default CustomEnumsTable;
