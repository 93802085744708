import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/layout/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

import Router from 'routes/index.js';

import './App.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router />
      </ThemeConfig>
    </QueryClientProvider>
  );
}

export default App;
