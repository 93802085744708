// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__pZuoh {
  padding: 3rem;
  margin-bottom: auto;
  width: 100%;
  height: 6rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_icon__b9Kf- {
  position: absolute;
  left: 2rem;
}

.styles_button__CyH9j {
  width: 10rem;
}

.styles_container__jwWr\\+ {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.styles_footer__pZ4kP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  padding: 0 2rem;
}

.styles_content__saVyz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.styles_buttons__U0t9W {
  display: flex;
  margin-left: auto;
  gap: 2rem;
  padding: 1.5rem 0;
}

.styles_divider__yXhSZ {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal-content/confirm/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,4BAAA;EACA,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAUF;;AAPA;EACE,kBAAA;EACA,UAAA;AAUF;;AAPA;EACE,YAAA;AAUF;;AAPA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AAUF;;AARA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AAWF;;AATA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAYF;;AAVA;EACE,aAAA;EACA,iBAAA;EACA,SAAA;EACA,iBAAA;AAaF;;AAXA;EACE,WAAA;AAcF","sourcesContent":["@import 'styles/common/constants.module';\n\n.header {\n  padding: 3rem;\n  margin-bottom: auto;\n  width: 100%;\n  height: 6rem;\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.icon {\n  position: absolute;\n  left: 2rem;\n}\n\n.button{\n  width: 10rem;\n}\n\n.container {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.footer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  margin-top: auto;\n  padding: 0 2rem;\n}\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n.buttons {\n  display: flex;\n  margin-left: auto;\n  gap: 2rem;\n  padding: 1.5rem 0;\n}\n.divider{\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"header": `styles_header__pZuoh`,
	"icon": `styles_icon__b9Kf-`,
	"button": `styles_button__CyH9j`,
	"container": `styles_container__jwWr+`,
	"footer": `styles_footer__pZ4kP`,
	"content": `styles_content__saVyz`,
	"buttons": `styles_buttons__U0t9W`,
	"divider": `styles_divider__yXhSZ`
};
export default ___CSS_LOADER_EXPORT___;
