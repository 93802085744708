import httpClient from 'httpClient';
import SERVICES from 'constants/services';

export const duplicateIndex = async (indexId) => {
  const { data } = await httpClient.post(`${SERVICES.indexes}/${indexId}${SERVICES.index}`);
  return data;
};

export const createIndex = async (indexValues) => {
  const { data } = await httpClient.post(`${SERVICES.indexes}/`, { ...indexValues });
  return data;
};

export const createIndexParameter = async (indexId, values) => {
  const { data } = await httpClient.post(`${SERVICES.indexes}/${indexId}${SERVICES.parameters}`, {
    ...values,
  });
  return data;
};

export const createIndexEntity = async (indexId, values) => {
  const { data } = await httpClient.post(`${SERVICES.indexes}/${indexId}${SERVICES.nodes}`, {
    ...values,
  });

  return data;
};

export const createIndexDataType = async (indexId, values) => {
  const { data } = await httpClient.post(`${SERVICES.indexes}/${indexId}${SERVICES.dataTypes}`, {
    ...values,
  });

  return data;
};

export const addCustomValue = async (indexId, enumId, valueData) => {
  const { data } = await httpClient.post(
    `${SERVICES.indexes}/${indexId}${SERVICES.customEnums}/${enumId}${SERVICES.customValues}`,
    {
      ...valueData,
    }
  );
  return data;
};

export const createCustomEnum = async (indexId, enumData) => {
  const { data } = await httpClient.post(`${SERVICES.indexes}/${indexId}${SERVICES.customEnums}`, {
    ...enumData,
    indexId,
  });
  return data;
};

export const createPaddock = async (establishmentId, values) => {
  const { data } = await httpClient.post(
    `${SERVICES.establishments}/${establishmentId}${SERVICES.paddocks}/`,
    {
      ...values,
    }
  );
  return data;
};
