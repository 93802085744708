import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Button, CircularProgress, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import CopyIcon from '@iconify/icons-eva/copy-outline';
import PlantIcon from '@iconify-icons/la/pagelines';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import cn from 'classnames/bind';

import OptionsCard from 'components/common/options-card';
import PageTitle from 'components/common/page-title';
import ROUTES from 'routes/routesPaths';
import SERVICES from 'constants/services';
import { duplicateIndex } from 'api/posts';
import { getIndex } from 'api/gets';

import styles from './styles.module.scss';
import toastSyles from 'styles/common/toast.module.scss';

const style = cn.bind(styles);

const IndexDetail = () => {
  const { indexId } = useParams();

  const [duplicate, setDuplicate] = useState(false);

  const navigate = useNavigate();

  const entidades = {
    option1: { label: 'VER ENTIDADES', onClick: () => navigate(`.${ROUTES.entities}`) },
    option2: {
      label: 'CREAR ENTIDAD',
      onClick: () => navigate(`${ROUTES.indexDetail}/${indexId}${ROUTES.createEntity}`),
    },
  };
  const tiposDeDato = {
    option1: { label: 'VER TIPOS DE DATO', onClick: () => navigate(`.${ROUTES.dataTypes}`) },
    option2: { label: 'CREAR TIPO DE DATO', onClick: () => navigate(`.${ROUTES.createDataType}`) },
  };

  const enums = {
    option1: { label: 'VER ENUMERADOS', onClick: () => navigate(`.${ROUTES.enums}`) },
    option2: { label: 'CREAR ENUMERADO', onClick: () => navigate(`.${ROUTES.createEnum}`) },
  };

  const parametrosIndice = {
    option1: {
      label: 'VER Y EDITAR PARÁMETROS',
      onClick: () => navigate(`.${ROUTES.params}`),
    },
  };

  const optionsCardsCommonProps = {
    titleClassName: 'h3-bold-18',
    cardClassName: styles.card,
    labelsClassName: 'p-medium-16',
  };

  const { isLoading: isLoadingDuplicate, isFetching: isFetchingDuplicate } = useQuery(
    [`${SERVICES.index}/duplicate`, indexId],
    () => duplicateIndex(indexId),
    {
      enabled: duplicate,
      onError: () => {
        setDuplicate(false);
        toast('Error: Something went wrong', { type: 'error', className: toastSyles.error });
      },
      onSuccess: () => {
        setDuplicate(false);
        toast('Indice duplicado', { type: 'success', className: toastSyles.success });
      },
    }
  );

  const { data: indexData, isLoading: isLoadingIndex } = useQuery([SERVICES.indexes, indexId], () =>
    getIndex(indexId)
  );

  const isLoadingDuplicatePost = isLoadingDuplicate || isFetchingDuplicate;

  const containerStyle = style({
    container: true,
    containerCenter: isLoadingIndex,
  });
  return (
    <div className={containerStyle}>
      {isLoadingIndex ? (
        <CircularProgress color="success" size={100} />
      ) : (
        <>
          <Button
            className={styles.button}
            variant="contained"
            loading={isLoadingDuplicatePost}
            onClick={() => setDuplicate(true)}
          >
            {isLoadingDuplicatePost ? (
              <CircularProgress color="success" />
            ) : (
              <>
                <Icon icon={CopyIcon} height="25" width="25" />
                <span> Duplicar Indice</span>
              </>
            )}
          </Button>
          <div className={styles.titleWrapper}>
            <PageTitle
              text="Detalle indice"
              icon={PlantIcon}
              iconClassName={styles.icon}
              isCenterText={true}
              showHr={false}
            />
          </div>
          <div className={styles.descriptionContainer}>
            <div className={styles.row}>
              <div className={`h2-bold-18 ${styles.label}`}>Nombre:</div>
              <Box
                component="div"
                sx={{
                  whiteSpace: 'normal',
                  overflowX: 'auto',
                  my: 2,
                  p: 1,
                  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                  color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                  border: '1px solid',
                  borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
                  borderRadius: 2,
                  fontSize: '1.8rem',
                  fontWeight: '700',
                  maxWidth: '50rem',
                  padding: '0.4rem 1.6rem',
                }}
              >
                {indexData?.name}
              </Box>
            </div>

            <div className={styles.row}>
              <div className={`h2-bold-18 ${styles.label}`}>Descripción:</div>
              <Box
                component="div"
                sx={{
                  whiteSpace: 'normal',
                  overflowX: 'auto',
                  my: 2,
                  p: 1,
                  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                  color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                  border: '1px solid',
                  borderColor: (theme) => (theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300'),
                  borderRadius: 2,
                  fontSize: '1.6rem',
                  fontWeight: '700',
                  maxWidth: '80rem',
                  padding: '0.4rem 1.6rem',
                }}
              >
                {indexData?.description}
              </Box>
            </div>
          </div>

          <div className={styles.cardsContainer}>
            <OptionsCard title="Entidades" {...optionsCardsCommonProps} {...entidades} />
            <OptionsCard title="Tipos de dato" {...optionsCardsCommonProps} {...tiposDeDato} />
            <OptionsCard title="Enumerados" {...optionsCardsCommonProps} {...enums} />
            <OptionsCard
              title="Parametros del indice"
              {...optionsCardsCommonProps}
              {...parametrosIndice}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default IndexDetail;
