// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__bJ-hi {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 2rem 2rem;
  text-align: center;
  width: 100%;
}

.styles_titleWrapper__64qGi {
  align-items: center;
  display: flex;
  max-width: 160rem;
}

.styles_content__93Cm7 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/backoffice/index/edit/styles.module.scss","webpack://./src/styles/common/mixins.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,oBAAA;EACA,kBAAA;EACA,WAAA;AASF;;AANA;EACE,mBAAA;EACA,aAAA;EACA,iBAAA;AASF;;AAPA;ECjBE,mBAAA;EACA,aAAA;EACA,sBDgBqB;ECfrB,uBAAA;EDgBA,gBAAA;EACA,WAAA;AAaF","sourcesContent":["@import 'styles/common/mixins';\n@import 'styles/common/constants.module';\n\n.container {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 0 2rem 2rem;\n  text-align: center;\n  width: 100%;\n}\n\n.titleWrapper {\n  align-items: center;\n  display: flex;\n  max-width: 160rem;\n}\n.content {\n  @include flex-center(column);\n  margin-top: 2rem;\n  width: 100%;\n}\n","@mixin flex-center($flex-direction: row) {\n  align-items: center;\n  display: flex;\n  flex-direction: $flex-direction;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"container": `styles_container__bJ-hi`,
	"titleWrapper": `styles_titleWrapper__64qGi`,
	"content": `styles_content__93Cm7`
};
export default ___CSS_LOADER_EXPORT___;
