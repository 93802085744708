import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// material

import {
  Box,
  Drawer,
  IconButton,
  Slide,
  Divider,
  Avatar,
  Button,
  Typography,
  Tooltip,
} from '@mui/material';
// components
import Scrollbar from 'components/layout/Scrollbar';
import NavSection from 'components/layout/NavSection';

import { MHidden } from 'components/@material-extend';
import { styled } from '@mui/material/styles';
//
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import userIcon from '@iconify/icons-eva/person-fill';
import logOut from '@iconify/icons-eva/log-out-outline';
import { useSession } from 'hooks';

import getSidebarConfig from 'routes/SidebarConfig';
import styles from './styles.module.scss';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 310;

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
  marginRight: '1rem',
  width: '24rem',
}));

const HeaderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '2rem 0',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  signOut: PropTypes.func,
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  setOpenSideBar,
  onLogout,
}) {
  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: isOpenSidebar && DRAWER_WIDTH,
    },
  }));

  const { session } = useSession();
  const { user } = session;
  const { indexId } = useParams();

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
        }}
      >
        <Box sx={{ mb: 5, mx: 0.5 }}>
          <HeaderStyle>
            <AccountStyle>
              <Avatar src={user?.profileImg}>
                <Icon icon={userIcon} width={42} height={42} />
              </Avatar>

              <Box sx={{ ml: 2, overflow: 'hidden' }}>
                <span className="h3-bold-18 gray"> {user?.name}</span>
                <br />
                <Tooltip title={user?.email} classes={{ tooltip: styles.tooltip }}>
                  <StyledTypography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                    }}
                  >
                    {user?.email}
                  </StyledTypography>
                </Tooltip>
              </Box>
            </AccountStyle>

            <IconButton
              onClick={() => setOpenSideBar(false)}
              size={'small'}
              sx={{ mr: 1, color: 'text.primary' }}
              style={{ height: 'fit-content' }}
            >
              <Icon icon={menu2Fill} width={30} height={30} />
            </IconButton>
          </HeaderStyle>
          <Divider variant="middle" />
        </Box>

        <NavSection navConfig={getSidebarConfig(indexId)} />
      </Scrollbar>
      <Box sx={{ p: 2, pt: 1.5 }} justifySelf={'flex-end'}>
        <Button fullWidth color="inherit" variant="outlined" onClick={onLogout}>
          <Icon
            icon={logOut}
            width={28}
            height={28}
            style={{ marginRight: '1rem', color: 'red' }}
          />
          Cerrar Sesión
        </Button>
      </Box>
    </>
  );

  return (
    <Slide direction="right" in={isOpenSidebar} mountOnEnter unmountOnExit>
      <RootStyle>
        <MHidden width="lgUp">
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>

        <MHidden width="lgDown">
          <Drawer
            open={isOpenSidebar}
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: 'background.default',
              },
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
      </RootStyle>
    </Slide>
  );
}
