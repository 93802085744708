import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Tooltip from '@mui/material/Tooltip';
import PlantIcon from '@iconify-icons/la/pagelines';
import CircularProgress from '@mui/material/CircularProgress';

import { toast } from 'react-toastify';

import PageTitle from 'components/common/page-title';
import ROUTES from 'routes/routesPaths';
import SERVICES from 'constants/services';
import { createCustomEnum } from 'api/posts';
import { getIndex } from 'api/gets';
import EnumForm from 'components/backoffice/enum-form';

import toastSyles from 'styles/common/toast.module.scss';
import styles from './styles.module.scss';

const CreateEnum = () => {
  const { indexId } = useParams();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
  });

  const queryClient = useQueryClient();

  const { data: indexData, isLoading: isLoadingIndex } = useQuery([SERVICES.indexes, indexId], () =>
    getIndex(indexId)
  );

  const { mutate: doCreateEnum, isLoading: isLoadingCreate } = useMutation(
    () => createCustomEnum(indexId, formValues),
    {
      onError: (error) => {
        toast(`Error: ${error?.message || 'Something went wrong'}`, {
          type: 'error',
          className: toastSyles.error,
        });
      },
      onSuccess: (newEnum) => {
        queryClient.invalidateQueries([SERVICES.customEnums, indexId]);
        toast('Enumerado creado', {
          type: 'success',
          className: toastSyles.success,
          onClose: () =>
            navigate(
              `${ROUTES.indexDetail}/${indexId}${ROUTES.enums}/${newEnum?.id}${ROUTES.edit}`
            ),
        });
      },
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    doCreateEnum();
  };

  return (
    <div className={styles.container}>
      {isLoadingIndex ? (
        <CircularProgress color="success" />
      ) : (
        <Tooltip
          title={<span className="btn-semibold-12 gray">Ir a detalle del indice</span>}
          classes={{ tooltip: styles.titleTooltip, arrow: styles.tooltipArrow }}
          placement="right"
          arrow
        >
          <div className={styles.titleWrapper}>
            <PageTitle
              text={indexData?.name}
              icon={PlantIcon}
              iconClassName={styles.icon}
              isCenterText={true}
              showHr={false}
              titleContainerClassName={styles.titleContainer}
              onClick={() => navigate(`${ROUTES.indexDetail}/${indexId}`)}
            />
          </div>
        </Tooltip>
      )}
      <div className={styles.subTitleWrapper}>
        <PageTitle
          text="Crear enumerado"
          isCenterText={true}
          showHr={false}
          titleContainerClassName={styles.titleContainer}
        />
      </div>

      <div className={styles.content}>
        <EnumForm
          formValues={formValues}
          setFormValues={setFormValues}
          onLeftClick={() => navigate(`${ROUTES.indexDetail}/${indexId}${ROUTES.enums}`)}
          onSubmit={onSubmit}
          indexId={indexId}
          isLoading={isLoadingCreate}
          isValuesEnabled={false}
        />
      </div>
    </div>
  );
};

export default CreateEnum;
