import httpClient from 'httpClient';
import SERVICES from 'constants/services';

const addQueryParams = (service, queryParams) => {
  if (!queryParams) {
    return service;
  } else {
    const keys = Object.keys(queryParams);

    let serviceWithQueryParams = service;
    keys.forEach((key, index) => {
      let delimiter = '&';
      const currentValue = queryParams[key];

      if (!(currentValue === null || currentValue === undefined)) {
        if (index === 0) {
          delimiter = '?';
        }
        serviceWithQueryParams = serviceWithQueryParams.concat(
          `${delimiter}${key}=${currentValue}`
        );
      }
    });
    return serviceWithQueryParams;
  }
};

export const getIndexs = async (status) => {
  const realService = status ? `${SERVICES.indexes}?status=${status}` : SERVICES.indexes;

  const service = process.env.REACT_APP_IS_MOCK === 'true' ? SERVICES.mocked.indexes : realService;

  const { data } = await httpClient.get(service);
  return data;
};

export const getIndexParameters = async (indexId, queryParams) => {
  const service =
    process.env.REACT_APP_IS_MOCK === 'true'
      ? SERVICES.mocked.parameters
      : addQueryParams(`${SERVICES.indexes}/${indexId}${SERVICES.parameters}`, queryParams);

  const { data } = await httpClient.get(service);
  return data;
};

export const getIndex = async (indexId) => {
  const service =
    process.env.REACT_APP_IS_MOCK === 'true'
      ? SERVICES.mocked.index
      : `${SERVICES.indexes}/${indexId}`;
  const { data } = await httpClient.get(service);

  return data;
};

export const getEntity = async (indexId, nodeId) => {
  const service =
    process.env.REACT_APP_IS_MOCK === 'true'
      ? SERVICES.mocked.nodes
      : `${SERVICES.indexes}/${indexId}${SERVICES.nodes}/${nodeId}`;
  const { data } = await httpClient.get(service);

  return data;
};

export const getDataTypes = async (indexId) => {
  const service =
    process.env.REACT_APP_IS_MOCK === 'true'
      ? SERVICES.mocked.dataTypes
      : `${SERVICES.indexes}/${indexId}${SERVICES.dataTypes}`;
  const { data } = await httpClient.get(service);

  return data;
};

export const getCustomEnums = async (indexId) => {
  const service =
    process.env.REACT_APP_IS_MOCK === 'true'
      ? SERVICES.mocked.customEnums
      : `${SERVICES.indexes}/${indexId}${SERVICES.customEnums}`;
  const { data } = await httpClient.get(service);
  return data;
};

export const getCustomEnum = async (indexId, enumId) => {
  const service =
    process.env.REACT_APP_IS_MOCK === 'true'
      ? SERVICES.mocked.customEnums
      : `${SERVICES.indexes}/${indexId}${SERVICES.customEnums}/${enumId}`;
  const { data } = await httpClient.get(service);
  return data;
};

export const getEstablishments = async () => {
  const service = `${SERVICES.establishments}/`;
  const { data } = await httpClient.get(service);

  return data;
};

export const getEstablishment = async (establishmentId) => {
  const service = `${SERVICES.establishments}/${establishmentId}`;
  const { data } = await httpClient.get(service);

  return data;
};

export const getEstablishmentPaddock = async (establishmentId, paddockId) => {
  const service = `${SERVICES.establishments}/${establishmentId}${SERVICES.paddocks}/${paddockId}`;
  const { data } = await httpClient.get(service);

  return data;
};

export const getMeasurements = async (establishmentId, paddockId) => {
  const service =
    process.env.REACT_APP_IS_MOCK === 'true'
      ? SERVICES.mocked.measurements
      : `${SERVICES.establishments}/${establishmentId}${SERVICES.paddocks}/${paddockId}${SERVICES.measurements}`;
  const { data } = await httpClient.get(service);

  return data;
};

export const getMeasurement = async (establishmentId, paddockId, measurementId) => {
  const service =
    process.env.REACT_APP_IS_MOCK === 'true'
      ? SERVICES.mocked.measurement
      : `${SERVICES.establishments}/${establishmentId}${SERVICES.paddocks}/${paddockId}${SERVICES.measurements}/${measurementId}`;
  const { data } = await httpClient.get(service);

  return data;
};

export const getPotentialVegetation = async (indexId) => {
  const service = `${SERVICES.indexes}/${indexId}${SERVICES.potentialVegetation}`;
  const { data } = await httpClient.get(service);

  return data;
};

export const getMeasurementCSV = async (establishmentId, paddockId, measurementId, isFull) => {
  const service = `${SERVICES.establishments}/${establishmentId}${SERVICES.paddocks}/${paddockId}${SERVICES.measurements}/${measurementId}/dataCsv?isFull=${isFull}`;
  const { data } = await httpClient.get(service);
  return data;
};
