import cn from 'classnames/bind';
import { bool, func, node, string } from 'prop-types';
import { IconButton, Card } from '@mui/material';
import CloseIcon from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';

import styles from './styles.module.scss';

const style = cn.bind(styles);

const Modal = ({
  showModal,
  setShowModal,
  children,
  containerClassName = '',
  closeIconColor = 'white',
  showCloseIcon = true,
  onClose,
}) => {
  const containerStyle = style({
    container: true,
    [containerClassName]: true,
    open: showModal,
  });
  return (
    <>
      {showModal && <div className={styles.overlay} />}
      <Card className={containerStyle}>
        {showCloseIcon && (
          <div
            className={styles.closeIcon}
            onClick={() => {
              setShowModal(false);
              onClose?.();
            }}
          >
            <IconButton>
              <Icon icon={CloseIcon} width={20} height={20} color={closeIconColor} />
            </IconButton>
          </div>
        )}

        <div className={styles.content}>{children}</div>
      </Card>
    </>
  );
};

Modal.propTypes = {
  showModal: bool.isRequired,
  setShowModal: func.isRequired,
  children: node,
  closeIconColor: string,
  containerClassName: string,
};

export default Modal;
