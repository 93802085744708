import { useCallback } from 'react';
import { shape, string, func, bool } from 'prop-types';
import { TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import styles from './styles.module.scss';

const ParameterForm = ({ formValues, setFormValues, onSubmit, isLoading, onLeftClick }) => {
  const onChange = useCallback(
    (inputKey, e) => {
      setFormValues({ ...formValues, [inputKey]: e.target.value });
    },
    [formValues, setFormValues]
  );

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <TextField
        id="name"
        label="Nombre"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        className={styles.input}
        required
        placeholder="Nombre"
        value={formValues.name || ''}
        onChange={(e) => onChange('name', e)}
        disabled={isLoading}
      />
      <TextField
        id="description"
        label="Descripción"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        className={styles.input}
        required
        placeholder="Descripción"
        value={formValues.description || ''}
        onChange={(e) => onChange('description', e)}
        disabled={isLoading}
      />
      <TextField
        id="value"
        label="Valor"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        className={styles.input}
        required
        placeholder="Valor"
        value={formValues.value || ''}
        onChange={(e) => onChange('value', e)}
        disabled={isLoading}
      />
      <div className={styles.buttons}>
        <Button
          variant="contained"
          size="large"
          disabled={isLoading}
          className={styles.button}
          onClick={onLeftClick}
        >
          <span className="h3-bold-18 white">Volver</span>
        </Button>
        <LoadingButton
          variant="contained"
          size="large"
          className={styles.button}
          loading={isLoading}
          type="submit"
        >
          {!isLoading && <span className="h3-bold-18 white">Confirmar</span>}
        </LoadingButton>
      </div>
    </form>
  );
};

ParameterForm.propTypes = {
  formValues: shape({
    name: string,
    value: string,
  }).isRequired,
  setFormValues: func.isRequired,
  onSubmit: func.isRequired,
  isLoading: bool,
  onLeftClick: func,
};

export default ParameterForm;
