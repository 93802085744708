import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

import PageTitle from 'components/common/page-title';
import PaddockForm from 'components/paddock-form';
import SERVICES from 'constants/services';
import { getEstablishment, getEstablishmentPaddock } from 'api/gets';
import { editPaddock } from 'api/puts';

import toastSyles from 'styles/common/toast.module.scss';
import styles from './styles.module.scss';

const EditEstablishment = () => {
  const { establishmentId, paddockId } = useParams();

  const queryClient = useQueryClient();
  const paddockCacheData = queryClient.getQueryData([
    `${SERVICES.establishments}/${establishmentId}`,
    paddockId,
  ]);

  const [formValues, setFormValues] = useState(
    paddockCacheData || {
      name: '',
      description: '',
      potentialVeg: {},
      hasZr: false,
      surface: 0, // TODO (review edit padock with surface att)
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    doEditPaddock();
  };

  const { data: establishmentData, isLoading: isLoadingEstablishment } = useQuery(
    [SERVICES.establishments, establishmentId],
    () => getEstablishment(establishmentId)
  );

  const { isLoading: isLoadingPaddock } = useQuery(
    [`${SERVICES.establishments}/${establishmentId}`, paddockId],
    () => getEstablishmentPaddock(establishmentId, paddockId),
    {
      onSuccess: (data) => {
        const { potentialVeg } = data;
        setFormValues({
          ...data,
          potentialVeg: {
            id: potentialVeg?.id,
            label: `${potentialVeg.value} - ${potentialVeg.name}`,
          },
        });
      },
    }
  );

  const { mutate: doEditPaddock, isLoading: isLoadingEditPaddock } = useMutation(
    () =>
      editPaddock(establishmentId, paddockId, {
        ...formValues,
        potentialVeg: formValues?.potentialVeg?.id,
      }),

    {
      onError: (error) => {
        toast(`Error: ${error?.message || 'Something went wrong'}`, {
          type: 'error',
          className: toastSyles.error,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([`${SERVICES.establishments}/${establishmentId}`, paddockId]);
        toast('Potrero editado', {
          type: 'success',
          className: toastSyles.success,
        });
      },
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        {isLoadingEstablishment ? (
          <CircularProgress color="success" />
        ) : (
          <PageTitle
            text={`Editar Establecimiento : ${establishmentData?.name}`}
            isCenterText={true}
            showHr={false}
          />
        )}
      </div>
      <div className={styles.subTitleWrapper}>
        <PageTitle text="Editar potrero" isCenterText={true} />
      </div>
      <div className={styles.content}>
        {isLoadingPaddock ? (
          <CircularProgress color="success" />
        ) : (
          <PaddockForm
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onSubmit}
            isLoading={isLoadingEditPaddock}
            enabled={true}
            establishmentId={establishmentId}
            indexId={establishmentData?.indexId}
          />
        )}
      </div>
    </div>
  );
};

export default EditEstablishment;
