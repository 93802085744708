import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import logOutIcon from '@iconify/icons-eva/log-out-outline';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import useSession from 'hooks/useSession';
import ROUTES from 'routes/routesPaths';
import Modal from 'components/common/modal';
import Confirm from 'components/common/modal-content/confirm';

import styles from './styles.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import colors from 'styles/common/constants.module.scss';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 44;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  width: '100%',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(true);
  const { session, signOut } = useSession();

  const navigate = useNavigate();

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const confirmRightButton = {
    label: 'Confirmar',
    onClick: () => signOut(),
  };

  const confirmLeftButton = {
    label: 'Cancelar',
    onClick: () => setIsOpenConfirm(false),
  };

  useEffect(() => {
    if (!session) return navigate(ROUTES.login);
  }, [session, navigate]);
  return (
    <RootStyle>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        openSideBar={open}
        onLogout={() => setIsOpenConfirm(true)}
      />
      <DashboardSidebar
        isOpenSidebar={open}
        setOpenSideBar={setOpen}
        onCloseSidebar={() => setOpen(false)}
        onLogout={() => setIsOpenConfirm(true)}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
      <Modal
        showModal={isOpenConfirm}
        setShowModal={setIsOpenConfirm}
        containerClassName={styles.confirmModalContainer}
        closeIconColor={colors.white}
      >
        <Confirm
          title="Confirmar"
          titleClassName={styles.confirmModalTitle}
          headerClassName={styles.confirmHeaderClassName}
          description="Desea cerrar sesión?"
          descriptionClassName="h2-bold-18"
          rightButton={confirmRightButton}
          leftButton={confirmLeftButton}
          icon={logOutIcon}
        />
      </Modal>
    </RootStyle>
  );
}
