// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_confirmModalContainer__SKBsB {
  max-height: 30rem;
}

.styles_confirmModalTitle__hGd92 {
  color: #ffffff;
}

.styles_confirmHeaderClassName__skOv5 {
  background-color: rgba(139, 13, 13, 0.6784313725);
}

.styles_tooltip__yU\\+4E {
  background-color: #ffffff;
  padding: 1.2rem;
  color: rgb(71, 69, 69);
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/layouts/dashboard/styles.module.scss","webpack://./src/styles/common/constants.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AAUF;;AAPA;EACE,cCGM;ADOR;;AAPA;EACE,iDCCG;ADSL;;AAPA;EACE,yBCLM;EDMN,eAAA;EACA,sBCVK;EDWL,eAAA;AAUF","sourcesContent":["@import 'styles/common/constants.module';\n\n.confirmModalContainer {\n  max-height: 30rem;\n}\n\n.confirmModalTitle {\n  color: $white;\n}\n\n.confirmHeaderClassName{\n  background-color: $red;\n}\n\n.tooltip{\n  background-color: $white;\n  padding: 1.2rem;\n  color: $gray;\n  font-size: 1rem;\n}","// Fonts weights\n$normal: 400;\n$medium: 500;\n$semibold: 600;\n$bold: 700;\n// Colors\n$black: #000000;\n$gray: rgb(71, 69, 69);\n$lightGray: rgb(134, 129, 129);\n$athensGray: #F9FAFB;\n$white: #ffffff;\n$overlay: rgba(218, 211, 211, 0.75);\n$red:#8b0d0dad;\n//Green\n$softestGreen: #e0fae3a1;\n$softGreen: #e0fae3;\n$lighter: #c8facd;\n$light: #5be584;\n$dark: #007b55;\n$darker: #005249;\n$ligthRed:#c40707c8;\n$orange: #e69603;\n$yellow: #ffea00f5;\n$lightGreen: #b0d04f;\n$main: #00ab55;\n\n$contrastText: #fff;\n// z-index levels\n$z-index-top: 1300;\n$z-index-middle: 1200;\n$z-index-bottom: 100;\n\n:export {\n  white: $white;\n  black: $black;\n  red:$red;\n  ligthRed:$ligthRed;\n  lightGreen:$lightGreen;\n  green: $main;\n  yellow:$yellow;\n  orange:$orange;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"confirmModalContainer": `styles_confirmModalContainer__SKBsB`,
	"confirmModalTitle": `styles_confirmModalTitle__hGd92`,
	"confirmHeaderClassName": `styles_confirmHeaderClassName__skOv5`,
	"tooltip": `styles_tooltip__yU+4E`
};
export default ___CSS_LOADER_EXPORT___;
