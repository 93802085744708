import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Button, Box, CircularProgress, Tooltip } from "@mui/material/";
import { Icon } from "@iconify/react";
import ZoomInIcon from "@iconify/icons-eva/maximize-outline";
import * as dayjs from "dayjs";
import clipboardIcon from "@iconify-icons/la/clipboard-list";
import fileDownload from "@iconify-icons/la/file-download";
import { toast } from "react-toastify";

import PageTitle from "components/common/page-title";
import Table from "components/table";
import {
  getIndex,
  getMeasurements,
  getEstablishment,
  getEstablishmentPaddock,
  getMeasurementCSV,
} from "api/gets";
import SERVICES from "constants/services";
import ROUTES from "routes/routesPaths";

import toastSyles from "styles/common/toast.module.scss";
import styles from "./styles.module.scss";

const Measurments = () => {
  const navigate = useNavigate();
  const [isResultsCSV, setIsResultsCSV] = useState(false);
  const { establishmentId, paddockId } = useParams();

  const { data: measurments, isLoading: isLoadingMeasurments } = useQuery(
    [SERVICES.measurements, establishmentId, paddockId],
    () => getMeasurements(establishmentId, paddockId)
  );

  const { data: establishmentData, isLoading: isLoadingEstablishment } =
    useQuery([SERVICES.establishments, establishmentId], () =>
      getEstablishment(establishmentId)
    );

  const { data: paddockData, isLoading: isLoadingPaddock } = useQuery(
    [SERVICES.paddocks, paddockId],
    () => getEstablishmentPaddock(establishmentId, paddockId)
  );

  const { data: indexData, isLoading: isLoadingIndex } = useQuery(
    [SERVICES.index, establishmentData?.indexId],
    () => getIndex(establishmentData?.indexId),
    { enabled: !!establishmentData }
  );

  const {
    mutate: getMeasurementCSVFile,
    isLoading: isLoadingGetMeasurmentCSV,
  } = useMutation(
    ({ establishmentId, paddockId, measurementId, isFull }) =>
      getMeasurementCSV(establishmentId, paddockId, measurementId, isFull),
    {
      onError: (error) => {
        toast(`Error: ${error?.message || "Something went wrong"}`, {
          type: "error",
          className: toastSyles.error,
        });
      },
      onSuccess: (data, variables) => {
        const { isFull, measurementId } = variables;
        const blob = new Blob([data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        const fileName = `${
          isFull ? "Datos" : "Resultados"
        }-establecimiento-${establishmentId}-potrero-${paddockId}-medida-${measurementId}.csv`;
        a.setAttribute("download", fileName);
        a.click();
      },
    }
  );

  const isLoadingDescription =
    isLoadingIndex || isLoadingPaddock || isLoadingEstablishment;

  const columns = [
    {
      field: "ver",
      headerName: "VER",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          navigate(
            `${ROUTES.establishments}/${establishmentId}${ROUTES.paddocks}/${paddockId}${ROUTES.displayMeasurement}/${params.row.id}`
          );
        };
        return (
          <div className={styles.buttonContainer}>
            <Button variant="outlined" onClick={(e) => onClick(e)}>
              <Icon icon={ZoomInIcon} width={23} height={23} />
            </Button>
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userEmail",
      headerName: "Email usuario",
      flex: 3,
    },
    {
      field: "dateTimeRecived",
      headerName: "Fecha",
      width: 130,
      flex: 2,
      type: "date",
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY - h:mm A");
      },
    },
    {
      field: "downloadCSVResults",
      headerName: "Exportar resultados",
      flex: 3,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          setIsResultsCSV(true);
          getMeasurementCSVFile({
            establishmentId,
            paddockId,
            measurementId: params.row.id,
            isFull: false,
          });
        };
        return (
          <div className={styles.buttonContainer}>
            <Button
              variant="outlined"
              onClick={(e) => onClick(e)}
              disabled={isLoadingGetMeasurmentCSV && isResultsCSV}
            >
              {isLoadingGetMeasurmentCSV && isResultsCSV ? (
                <CircularProgress color="success" size={30} />
              ) : (
                <Icon icon={fileDownload} width={30} height={30} />
              )}
            </Button>
          </div>
        );
      },
    },
    {
      field: "downloadCSVData",
      headerName: "Exportar datos cargados",
      flex: 3,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          setIsResultsCSV(false);
          getMeasurementCSVFile({
            establishmentId,
            paddockId,
            measurementId: params.row.id,
            isFull: true,
          });
        };
        return (
          <div className={styles.buttonContainer}>
            <Button
              variant="outlined"
              onClick={(e) => onClick(e)}
              disabled={isLoadingGetMeasurmentCSV && !isResultsCSV}
            >
              {isLoadingGetMeasurmentCSV && !isResultsCSV ? (
                <CircularProgress color="success" size={30} />
              ) : (
                <Icon icon={fileDownload} width={30} height={30} />
              )}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <PageTitle
            text="Medidas"
            titleContainerClassName={styles.title}
            showHr={false}
            icon={clipboardIcon}
          />
        </div>

        {isLoadingDescription ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <div className={styles.descriptionContainer}>
            <div className={styles.row}>
              <div className={`h2-bold-18 ${styles.label}`}>
                Establecimiento:
              </div>
              <Tooltip
                arrow
                title={
                  <span className="btn-semibold-12 gray">
                    Ir a detalle del establecimiento
                  </span>
                }
                classes={{
                  tooltip: styles.tooltip,
                  arrow: styles.tooltipArrow,
                }}
                placement="right"
              >
                <Box
                  component="div"
                  sx={{
                    whiteSpace: "normal",
                    overflowX: "auto",
                    my: 2,
                    p: 1,
                    bgcolor: (theme) =>
                      theme.palette.mode === "dark" ? "#101010" : "grey.100",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                    border: "1px solid",
                    borderColor: (theme) =>
                      theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                    borderRadius: 2,
                    fontSize: "1.8rem",
                    fontWeight: "700",
                    maxWidth: "50rem",
                    padding: "0.4rem 1.6rem",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(
                      `${ROUTES.establishments}/${establishmentData?.id}${ROUTES.display}`
                    )
                  }
                >
                  {establishmentData?.name}
                </Box>
              </Tooltip>
            </div>

            <div className={styles.row}>
              <div className={`h2-bold-18 ${styles.label}`}>Potrero:</div>
              <Box
                component="div"
                sx={{
                  whiteSpace: "normal",
                  overflowX: "auto",
                  my: 2,
                  p: 1,
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#101010" : "grey.100",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                  border: "1px solid",
                  borderColor: (theme) =>
                    theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                  borderRadius: 2,
                  fontSize: "1.6rem",
                  fontWeight: "700",
                  maxWidth: "80rem",
                  padding: "0.4rem 1.6rem",
                }}
              >
                {paddockData?.name}
              </Box>
            </div>
            <div className={styles.row}>
              <div className={`h2-bold-18 ${styles.label}`}>Indice:</div>
              <Tooltip
                arrow
                title={
                  <span className="btn-semibold-12 gray">
                    Ir a detalle del indice
                  </span>
                }
                classes={{
                  tooltip: styles.tooltip,
                  arrow: styles.tooltipArrow,
                }}
                placement="right"
              >
                <Box
                  component="div"
                  sx={{
                    whiteSpace: "normal",
                    overflowX: "auto",
                    my: 2,
                    p: 1,
                    bgcolor: (theme) =>
                      theme.palette.mode === "dark" ? "#101010" : "grey.100",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                    border: "1px solid",
                    borderColor: (theme) =>
                      theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                    borderRadius: 2,
                    fontSize: "1.6rem",
                    fontWeight: "700",
                    maxWidth: "80rem",
                    padding: "0.4rem 1.6rem",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(`${ROUTES.indexDetail}/${indexData.id}`)
                  }
                >
                  {indexData?.name}
                </Box>
              </Tooltip>
            </div>
          </div>
        )}

        <div className={styles.content}>
          <Table
            rows={measurments || []}
            columns={columns}
            cardClassName={styles.table}
            loading={isLoadingMeasurments}
          />
        </div>
      </div>
    </>
  );
};

export default Measurments;
