// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__z8lQx {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 2rem 2rem;
  text-align: center;
  width: 100%;
}

.styles_titleWrapper__5sInl {
  align-items: center;
  display: flex;
  max-width: 160rem;
  position: fixed;
  top: 0.5rem;
  z-index: 1200;
}

.styles_content__yDi4j {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/establishment/display/styles.module.scss","webpack://./src/styles/common/constants.module.scss","webpack://./src/styles/common/mixins.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,oBAAA;EACA,kBAAA;EACA,WAAA;AASF;;AANA;EACE,mBAAA;EACA,aAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,aCUe;ADDjB;;AANA;EErBE,mBAAA;EACA,aAAA;EACA,sBFoBqB;EEnBrB,uBAAA;EFoBA,gBAAA;EACA,WAAA;AAYF","sourcesContent":["@import 'styles/common/mixins';\n@import 'styles/common/constants.module';\n\n.container {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 0 2rem 2rem;\n  text-align: center;\n  width: 100%;\n}\n\n.titleWrapper {\n  align-items: center;\n  display: flex;\n  max-width: 160rem;\n  position: fixed;\n  top: 0.5rem;\n  z-index: $z-index-middle;\n}\n\n.content {\n  @include flex-center(column);\n  margin-top: 4rem;\n  width: 100%;\n}\n","// Fonts weights\n$normal: 400;\n$medium: 500;\n$semibold: 600;\n$bold: 700;\n// Colors\n$black: #000000;\n$gray: rgb(71, 69, 69);\n$lightGray: rgb(134, 129, 129);\n$athensGray: #F9FAFB;\n$white: #ffffff;\n$overlay: rgba(218, 211, 211, 0.75);\n$red:#8b0d0dad;\n//Green\n$softestGreen: #e0fae3a1;\n$softGreen: #e0fae3;\n$lighter: #c8facd;\n$light: #5be584;\n$dark: #007b55;\n$darker: #005249;\n$ligthRed:#c40707c8;\n$orange: #e69603;\n$yellow: #ffea00f5;\n$lightGreen: #b0d04f;\n$main: #00ab55;\n\n$contrastText: #fff;\n// z-index levels\n$z-index-top: 1300;\n$z-index-middle: 1200;\n$z-index-bottom: 100;\n\n:export {\n  white: $white;\n  black: $black;\n  red:$red;\n  ligthRed:$ligthRed;\n  lightGreen:$lightGreen;\n  green: $main;\n  yellow:$yellow;\n  orange:$orange;\n}\n","@mixin flex-center($flex-direction: row) {\n  align-items: center;\n  display: flex;\n  flex-direction: $flex-direction;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"container": `styles_container__z8lQx`,
	"titleWrapper": `styles_titleWrapper__5sInl`,
	"content": `styles_content__yDi4j`
};
export default ___CSS_LOADER_EXPORT___;
