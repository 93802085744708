import * as React from 'react';
import { useMemo, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { Icon } from '@iconify/react';
import plusIcon from '@iconify/icons-eva/plus-square-outline';
import minusIcon from '@iconify/icons-eva/minus-square-outline';
import leafIcon from '@iconify-icons/la/leaf';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@iconify/icons-eva/edit-2-fill';
import { useQuery } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import ROUTES from 'routes/routesPaths';
import { getIndex } from 'api/gets';
import SERVICES from 'constants/services';
//import { styled } from '@mui/material/styles';
import colors from 'styles/common/constants.module.scss';
import styles from './styles.module.scss';
import { makeStyles } from '@mui/styles';

const iconsDimensions = {
  width: 35,
  height: 35,
};

const actionItemIconDimensions = {
  width: 23,
  height: 23,
};

function MinusSquare() {
  return (
    <div className={styles.iconContainer}>
      <IconButton color="primary">
        <Icon icon={minusIcon} width={iconsDimensions.width} height={iconsDimensions.height} />
      </IconButton>
    </div>
  );
}

function PlusSquare() {
  return (
    <div className={styles.iconContainer}>
      <IconButton color="primary">
        <Icon icon={plusIcon} width={iconsDimensions.width} height={iconsDimensions.height} />
      </IconButton>
    </div>
  );
}

function LeafIcon() {
  return (
    <div className={styles.leafIconContainer}>
      <IconButton color="primary">
        <Icon icon={leafIcon} width={iconsDimensions.width} height={iconsDimensions.height} />
      </IconButton>
    </div>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

const useStyles = makeStyles({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed rgba(0, 0, 0, 0.4)`,
  },
});

const StyledTreeItem = (props) => {
  const classes = useStyles();
  return <TreeItem {...props} classes={{ iconContainer: classes.iconContainer, group: classes.group }} />;
};

const CustomTreeView = ({
  indexId,
  editItemIcon,
  editItemFunction,
  deleteItemIcon,
  deleteItemFunction,
  actionItemIcon,
  actionItemFunction,
  defaultExpandedItems = ['1', '2', '3'],
}) => {
  const navigate = useNavigate();

  const { data: indexData, isLoading: isLoadingIndex } = useQuery([SERVICES.indexes, indexId], () =>
    getIndex(indexId)
  );

  const onClickItem = useCallback(
    (item) => {
      if (actionItemFunction) {
        actionItemFunction(item);
      } else {
        editItemFunction
          ? editItemFunction(item)
          : navigate(`${ROUTES.indexDetail}/${indexId}${ROUTES.editEntity}/${item.id}`);
      }
    },
    [actionItemFunction, editItemFunction, indexId, navigate]
  );

  const generateTree = useCallback(
    (items) => {
      return items.map((item) => {
        const label = (
          <div className={styles.item}>
            <span className="h2-bold-18">{item?.name}</span>
            <Button
              className={styles.editButton}
              variant="outlined"
              shape={'square'}
              onClick={(e) => {
                e.stopPropagation();
                onClickItem(item);
              }}
            >
              <Icon
                icon={actionItemIcon ? actionItemIcon : editItemIcon || EditIcon}
                width={actionItemIconDimensions.width}
                height={actionItemIconDimensions.height}
              />
            </Button>
            {deleteItemFunction && (
              <IconButton onClick={(e) => deleteItemFunction(e, item)}>
                {deleteItemIcon ? (
                  <Icon
                    icon={editItemIcon || EditIcon}
                    width={actionItemIconDimensions.width}
                    height={actionItemIconDimensions.height}
                  />
                ) : (
                  <DeleteIcon sx={{ fontSize: 25, color: colors?.red || 'red' }} />
                )}
              </IconButton>
            )}
          </div>
        );

        if (item.children)
          return (
            <StyledTreeItem key={item.id} nodeId={String(item.id)} label={label}>
              {generateTree(item.children)}
            </StyledTreeItem>
          );
        else return <StyledTreeItem key={item.id} nodeId={item.id} label={item?.name} />;
      });
    },
    [editItemIcon, deleteItemFunction, deleteItemIcon, actionItemIcon, onClickItem]
  );

  const jsonTree = useMemo(() => indexData?.root && [indexData.root], [indexData]);

  const treeStructure = useMemo(() => jsonTree && generateTree(jsonTree), [jsonTree, generateTree]);
  return (
    <>
      {isLoadingIndex ? (
        <CircularProgress />
      ) : (
        <TreeView
          aria-label="customized"
          defaultExpanded={defaultExpandedItems}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<LeafIcon />}
          className={styles.treeContainer}
        >
          {treeStructure}
        </TreeView>
      )}
    </>
  );
};

CustomTreeView.propTypes = {
  defaultExpandedItems: PropTypes.array,
  actionItemIcon: PropTypes.object,
  actionItemFunction: PropTypes.func,
};

export default CustomTreeView;
