import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

import PageTitle from 'components/common/page-title';
import ROUTES from 'routes/routesPaths';
import SERVICES from 'constants/services';
import { getCustomEnum } from 'api/gets';
import { editCustomEnum } from 'api/puts';
import EnumForm from 'components/backoffice/enum-form';

import toastSyles from 'styles/common/toast.module.scss';
import styles from './styles.module.scss';

const EditEnum = () => {
  const { indexId, enumId } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const enumCacheData = queryClient.getQueryData([SERVICES.customEnums, enumId]);

  const [formValues, setFormValues] = useState(
    enumCacheData || {
      name: '',
      description: '',
      valuesList: [],
    }
  );

  const { isLoading: isLoadingEnum } = useQuery(
    [SERVICES.customEnums, enumId],
    () => getCustomEnum(indexId, enumId),
    {
      onSuccess: (response) => setFormValues(response),
    }
  );

  const { mutate: doEditEnum, isLoading: isLoadingEditEnum } = useMutation(
    () =>
      editCustomEnum(indexId, enumId, {
        name: formValues?.name,
        description: formValues?.description,
      }),
    {
      onError: (error) => {
        toast(`Error: ${error?.message || 'Something went wrong'}`, {
          type: 'error',
          className: toastSyles.error,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([SERVICES.customEnums, indexId]);
        queryClient.invalidateQueries([SERVICES.customEnums, enumId]);
        toast('Enumerado editado', {
          type: 'success',
          className: toastSyles.success,
          onClose: () => navigate(`${ROUTES.indexDetail}/${indexId}${ROUTES.enums}`),
        });
      },
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    doEditEnum();
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <PageTitle
          text="Editar enumerado"
          isCenterText={true}
          showHr={false}
          titleContainerClassName={styles.titleContainer}
        />
      </div>

      <div className={styles.content}>
        {isLoadingEnum ? (
          <CircularProgress />
        ) : (
          <EnumForm
            formValues={formValues}
            setFormValues={setFormValues}
            onLeftClick={() => navigate(`${ROUTES.indexDetail}/${indexId}${ROUTES.enums}`)}
            onSubmit={onSubmit}
            indexId={indexId}
            enumId={enumId}
            isLoading={isLoadingEnum || isLoadingEditEnum}
          />
        )}
      </div>
    </div>
  );
};

export default EditEnum;
