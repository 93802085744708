// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_form__fYIm0 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50rem;
  row-gap: 3rem;
  width: 100%;
}

.styles_input__vYZwo {
  width: 100%;
}

.styles_inputLarge__0G7PY {
  width: 100%;
}

.styles_button__SVIfX {
  width: 100%;
  max-width: 12rem;
}

.styles_buttons__u4seU {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  width: 100%;
}

.styles_labelContainer__CJJHY {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  width: 100%;
}

.styles_inputSelect__IxlL8 {
  width: 100%;
}

.styles_label__dOmor {
  text-align: left;
  white-space: nowrap;
  width: 100%;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/components/paddock-form/styles.module.scss","webpack://./src/styles/common/mixins.scss"],"names":[],"mappings":"AAGA;ECFE,mBAAA;EACA,aAAA;EACA,sBDCqB;ECArB,uBAAA;EDCA,gBAAA;EACA,aAAA;EACA,WAAA;AAYF;;AATA;EACE,WAAA;AAYF;;AATA;EACE,WAAA;AAYF;;AAVA;EACE,WAAA;EACA,gBAAA;AAaF;;AAVA;ECrBE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;EDoBA,SAAA;EACA,WAAA;AAgBF;;AAbA;EACE,mBAAA;EACA,aAAA;EACA,SAAA;EACA,2BAAA;EACA,WAAA;AAgBF;;AAbA;EACE,WAAA;AAgBF;;AAbA;EACE,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;AAgBF","sourcesContent":["@import 'styles/common/mixins';\n@import 'styles/common/constants.module';\n\n.form {\n  @include flex-center(column);\n  max-width: 50rem;\n  row-gap: 3rem;\n  width: 100%;\n}\n\n.input {\n  width: 100%;\n}\n\n.inputLarge {\n  width: 100%;\n}\n.button {\n  width: 100%;\n  max-width: 12rem;\n}\n\n.buttons {\n  @include flex-center();\n  gap: 2rem;\n  width: 100%;\n}\n\n.labelContainer{\n  align-items: center;\n  display: flex;\n  gap: 1rem;\n  justify-content: flex-start;\n  width: 100%;\n}\n\n.inputSelect{\n  width: 100%;\n}\n\n.label{\n  text-align: left;\n  white-space: nowrap;\n  width: 100%;\n  width: fit-content;\n}\n","@mixin flex-center($flex-direction: row) {\n  align-items: center;\n  display: flex;\n  flex-direction: $flex-direction;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"form": `styles_form__fYIm0`,
	"input": `styles_input__vYZwo`,
	"inputLarge": `styles_inputLarge__0G7PY`,
	"button": `styles_button__SVIfX`,
	"buttons": `styles_buttons__u4seU`,
	"labelContainer": `styles_labelContainer__CJJHY`,
	"inputSelect": `styles_inputSelect__IxlL8`,
	"label": `styles_label__dOmor`
};
export default ___CSS_LOADER_EXPORT___;
