import { useCallback, useState } from 'react';
import { shape, string, func, bool, number } from 'prop-types';
import { LoadingButton } from '@mui/lab';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import CustomTreeView from 'components/common/custom-tree-view';
import selectIcon from '@iconify-icons/la/arrow-left';
import { TextField, Button, InputAdornment, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames/bind';

import Modal from 'components/common/modal';
import DataTypesTable from 'components/tables/data-types';

import colors from 'styles/common/constants.module.scss';
import styles from './styles.module.scss';

const style = cn.bind(styles);

const EntityForm = ({
  formValues,
  setFormValues,
  onSubmit,
  isLoading,
  onLeftClick,
  indexId,
  entityId,
  isShowDataTable = true,
  isFatherEnabled = true,
}) => {
  const onChange = useCallback(
    (inputKey, value) => {
      setFormValues({ ...formValues, [inputKey]: value });
    },
    [formValues, setFormValues]
  );

  const [openFatherModal, setOpenFatherModal] = useState(false);

  const onSelectFather = (itemData) => {
    setOpenFatherModal(false);
    onChange('father', { ...itemData });
  };

  const getCommonInputProps = useCallback(
    (key, label) => ({
      id: key,
      label: label,
      placeholder: label,
      variant: 'outlined',
      InputLabelProps: { shrink: true },
      className: styles.input,
      value: formValues?.[key] || '',
      onChange: (e) => onChange(key, e.target.value),
      disabled: isLoading,
    }),
    [formValues, isLoading, onChange]
  );

  const inputsStyle = style({
    inputs: true,
    inputsGrid: isShowDataTable,
  });

  const inputFatherStyle = style({
    fatherInput: true,
    fatherInputFullWidth: !isFatherEnabled,
  });

  return (
    <>
      <form className={styles.form} onSubmit={onSubmit}>
        <div className={inputsStyle}>
          <TextField {...getCommonInputProps('shortName', 'Nombre corto')} required />
          <TextField {...getCommonInputProps('name', 'Nombre')} required />
          <div className={styles.inputRow}>
            <Tooltip
              arrow
              title={
                <span className="btn-semibold-12 gray">
                  {isFatherEnabled
                    ? 'Seleccione el padre haciendo click en la lupa'
                    : 'El padre no es editable'}
                </span>
              }
              classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
            >
              <TextField
                {...getCommonInputProps('father', 'Padre')}
                required={false}
                disabled={isLoading || !isFatherEnabled}
                value={formValues?.father?.name || ''}
                className={inputFatherStyle}
                placeholder=""
                onChange={() => {}}
                InputProps={{
                  ...(isFatherEnabled && {
                    endAdornment: (
                      <InputAdornment onClick={() => onChange('father', {})} position="end">
                        <IconButton>
                          <CloseIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }),
                }}
              />
            </Tooltip>
            {isFatherEnabled && (
              <Fab
                color="primary"
                aria-label="add"
                className={styles.searchButton}
                onClick={() => setOpenFatherModal(true)}
              >
                <SearchIcon label="buscar" sx={{ fontSize: 30 }}>
                  Buscar
                </SearchIcon>
              </Fab>
            )}
          </div>
          <TextField
            {...getCommonInputProps('formula', 'Fórmula')}
            multiline
            InputProps={{
              classes: {
                input: styles.inputLarge,
              },
            }}
          />
        </div>
        {isShowDataTable && (
          <div className={styles.tableWrapper}>
            <DataTypesTable
              rows={formValues?.dataTypes}
              indexId={indexId}
              entityId={entityId}
              isLoading={isLoading}
              tableClassName={styles.table}
            />
          </div>
        )}

        <div className={styles.buttons}>
          <Button
            variant="contained"
            size="large"
            className={styles.button}
            disabled={isLoading}
            onClick={onLeftClick}
          >
            <span className="h3-bold-18 white">Volver</span>
          </Button>
          <LoadingButton
            variant="contained"
            size="large"
            className={styles.button}
            loading={isLoading}
            type="submit"
          >
            {!isLoading && <span className="h3-bold-18 white">Confirmar</span>}
          </LoadingButton>
        </div>
      </form>
      <Modal
        showModal={openFatherModal}
        setShowModal={setOpenFatherModal}
        containerClassName={styles.modalContainer}
        closeIconColor={colors.black}
      >
        <div className={styles.modalContent}>
          <CustomTreeView
            indexId={indexId}
            actionItemIcon={selectIcon}
            actionItemFunction={onSelectFather}
          />
        </div>
      </Modal>
    </>
  );
};

EntityForm.propTypes = {
  formValues: shape({
    name: string,
    value: string,
  }).isRequired,
  setFormValues: func.isRequired,
  onSubmit: func.isRequired,
  isLoading: bool,
  onLeftClick: func,
  indexId: number,
  entityId: number,
  isShowDataTable: bool,
};

export default EntityForm;
