import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import PlantIcon from '@iconify-icons/la/pagelines';
import AddIcon from '@mui/icons-material/Add';
import { Fab, Tooltip } from '@mui/material';

import PageTitle from 'components/common/page-title';
import SERVICES from 'constants/services';
import { getDataTypes, getIndex } from 'api/gets';
import ROUTES from 'routes/routesPaths';
import DataTypesTable from 'components/tables/data-types';

import styles from './styles.module.scss';

const IndexDataTypes = () => {
  const { indexId } = useParams();
  const navigate = useNavigate();

  const { data: indexData } = useQuery([SERVICES.indexes, indexId], () => getIndex(indexId));

  const { data: dataTypes, isLoading } = useQuery([SERVICES.dataTypes, indexId], () =>
    getDataTypes(indexId)
  );

  return (
    <>
      <div className={styles.container}>
        <Tooltip
          title={<span className="btn-semibold-12 gray">Ir a detalle del indice</span>}
          classes={{ tooltip: styles.titleTooltip, arrow: styles.tooltipArrow }}
          placement="right"
          arrow
        >
          <div className={styles.titleWrapper}>
            <PageTitle
              text={indexData?.name}
              icon={PlantIcon}
              iconClassName={styles.icon}
              isCenterText={true}
              showHr={false}
              titleContainerClassName={styles.titleContainer}
              onClick={() => navigate(`${ROUTES.indexDetail}/${indexId}`)}
            />
          </div>
        </Tooltip>
        <div className={styles.subtitleContainer}>
          <div className={styles.subtitle}>
            <span className="h2-bold-24">Tipos de dato</span>
            <hr className={styles.divider} />
          </div>
        </div>
        <div className={styles.table}>
          <DataTypesTable
            rows={dataTypes?.dataTypeList}
            indexId={indexId}
            isLoading={isLoading}
            tableClassName={styles.table}
            pageSize={7}
            rowsPerPageOptions={[6]}
            isAddEnabled={false}
          />
        </div>
      </div>
      <Fab
        color="primary"
        aria-label="add"
        className={styles.addButton}
        onClick={() => navigate(`./..${ROUTES.createDataType}`)}
      >
        <AddIcon label="agregar" sx={{ fontSize: 35 }}>
          Agregar
        </AddIcon>
      </Fab>
    </>
  );
};

export default IndexDataTypes;
