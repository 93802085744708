import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Chip } from '@mui/material';

import { useSession } from 'hooks';
import ROUTES from 'routes/routesPaths';
import LoginForm from 'components/login-form';
import Logo from 'assets/logo.png';

import styles from './styles.module.scss';

const Login = () => {
  const { session } = useSession();

  const navigate = useNavigate();

  useEffect(() => {
    if (session) return navigate(ROUTES.root);
  }, [session, navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.titlesContainer}>
          <div className={styles.logoMobile}>
            <img src={Logo} alt="logo" />
          </div>
          <Card className={styles.cardTitle}>
            <span className={`${styles.title} h1-bold-32`}>Arrayan App </span>
            <Chip label="admin" color="success" variant="outlined" className={styles.chip} />
          </Card>
          <Card className={styles.cardTitle}>
            <span className={`${styles.title} h1-bold-20`}>Módulo de gestión del indice </span>
          </Card>
          <Card className={styles.cardTitle}>
            <span className={`${styles.title} h1-bold-30`}>Índice de Integridad Ecosistémica </span>
          </Card>
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
