import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserClock from '@iconify-icons/la/user-clock';
import LoginIcon from '@iconify/icons-eva/log-in-outline';
import ROUTES from 'routes/routesPaths';

import { Icon } from '@iconify/react';
import Logo from 'assets/logo.png';
import { Button } from '@mui/material';

import styles from './styles.module.scss';

const ExpiredSession = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <Icon icon={UserClock} height="150" width="150" className={styles.icon} />
      <p className={styles.title}>Sesión expirada </p>
      <p className={styles.description}>Su sesión ha expirado, debe volver a iniciar sesión</p>
      <Button
        variant="contained"
        size="large"
        // disabled={isLoading}
        className={styles.button}
        onClick={() => navigate(ROUTES.login)}
        type="button"
      >
        <Icon icon={LoginIcon} height="30" width="30" className={styles.homeIcon} />
        <span className="h3-bold-18 white">Iniciar sesión</span>
      </Button>
      <div className={styles.logoLeft}>
        <img src={Logo} alt="logo" className={styles.logo} />
      </div>
      <div className={styles.logoRight}>
        <img src={Logo} alt="logo" className={styles.logo} />
      </div>
    </div>
  );
};
export default ExpiredSession;
