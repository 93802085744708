import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageTitle from 'components/common/page-title';
import EstablishmentForm from 'components/establishment-form';
import SERVICES from 'constants/services';
import { getEstablishment } from 'api/gets';
import { editEstablishment } from 'api/puts';

import toastSyles from 'styles/common/toast.module.scss';
import styles from './styles.module.scss';

const EditEstablishment = () => {
  const { establishmentId } = useParams();

  const queryClient = useQueryClient();
  const establishmentCacheData = queryClient.getQueryData([
    SERVICES.establishments,
    establishmentId,
  ]);
  const [formValues, setFormValues] = useState(
    establishmentCacheData || {
      name: '',
      producer: '',
      department: '',
      location: '',
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    doEditEstablishment();
  };

  const { isLoading } = useQuery(
    [SERVICES.establishments, establishmentId],
    () => getEstablishment(establishmentId),
    {
      onSuccess: (data) => setFormValues(data),
    }
  );

  const { mutate: doEditEstablishment, isLoading: isLoadingEditEstablishment } = useMutation(
    () => editEstablishment(establishmentId, formValues),

    {
      onError: (error) => {
        toast(`Error: ${error?.message || 'Something went wrong'}`, {
          type: 'error',
          className: toastSyles.error,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([SERVICES.establishments, establishmentId]);
        toast('Establecimiento editado', {
          type: 'success',
          className: toastSyles.success,
        });
      },
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <PageTitle
          text={`Editar Establecimiento : ${formValues?.name}`}
          isCenterText={true}
          showHr={false}
        />
      </div>

      <div className={styles.content}>
        <EstablishmentForm
          formValues={formValues}
          setFormValues={setFormValues}
          onSubmit={onSubmit}
          isLoading={isLoading || isLoadingEditEstablishment}
          enabled={true}
          establishmentId={establishmentId}
        />
      </div>
    </div>
  );
};

export default EditEstablishment;
