import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import cn from "classnames/bind";
import * as dayjs from "dayjs";

import PageTitle from "components/common/page-title";
import MeasurmentTree from "components/measurment-tree";
import {
  getIndex,
  getMeasurement,
  getEstablishment,
  getEstablishmentPaddock,
} from "api/gets";
import ROUTES from "routes/routesPaths";

import SERVICES from "constants/services";

import styles from "./styles.module.scss";

const style = cn.bind(styles);

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const DisplayMeasurement = () => {
  const { establishmentId, paddockId, measurementId } = useParams();

  const containerStyle = style({
    container: true,
  });

  const { data: measurementData, isLoading: isLoadingMeasurement } = useQuery(
    [SERVICES.measurements, establishmentId, paddockId, measurementId],
    () => getMeasurement(establishmentId, paddockId, measurementId)
  );

  const { data: establishmentData, isLoading: isLoadingEstablishment } =
    useQuery([SERVICES.establishments, establishmentId], () =>
      getEstablishment(establishmentId)
    );

  const { data: paddockData, isLoading: isLoadingPaddock } = useQuery(
    [SERVICES.paddocks, paddockId],
    () => getEstablishmentPaddock(establishmentId, paddockId)
  );

  const { data: indexData, isLoading: isLoadingIndex } = useQuery(
    [SERVICES.index, establishmentData?.indexId],
    () => getIndex(establishmentData?.indexId),
    { enabled: !!establishmentData }
  );

  const navigate = useNavigate();

  const isLoading =
    isLoadingPaddock || isLoadingEstablishment || isLoadingMeasurement;

  return (
    <>
      <div className={containerStyle}>
        <div className={styles.titleWrapper}>
          <PageTitle
            text="Ver Medida"
            isCenterText={true}
            showHr={false}
            titleContainerClassName={styles.titleContainer}
          />
        </div>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <div className={styles.descriptionContainer}>
            <div className={styles.row}>
              <div className={styles.row}>
                <div className={`h2-bold-18 ${styles.label}`}>Fecha:</div>
                <Box
                  component="div"
                  sx={{
                    whiteSpace: "normal",
                    overflowX: "auto",
                    my: 2,
                    p: 1,
                    bgcolor: (theme) =>
                      theme.palette.mode === "dark" ? "#101010" : "grey.100",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                    border: "1px solid",
                    borderColor: (theme) =>
                      theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                    borderRadius: 2,
                    fontSize: "1.6rem",
                    fontWeight: "700",
                    maxWidth: "80rem",
                    padding: "0.4rem 1.6rem",
                  }}
                >
                  {measurementData &&
                    dayjs
                      .utc(measurementData?.dateTimeRecived)
                      .tz("America/Montevideo")
                      .format("DD/MM/YYYY h:mm A")}
                </Box>
              </div>
              <div className={styles.row}>
                <div className={`h2-bold-18 ${styles.label}`}>
                  Establecimiento:
                </div>
                <Tooltip
                  arrow
                  title={
                    <span className="btn-semibold-12 gray">
                      Ir a detalle del establecimiento
                    </span>
                  }
                  classes={{
                    tooltip: styles.tooltip,
                    arrow: styles.tooltipArrow,
                  }}
                  placement="right"
                >
                  <Box
                    component="div"
                    sx={{
                      whiteSpace: "normal",
                      overflowX: "auto",
                      my: 2,
                      p: 1,
                      bgcolor: (theme) =>
                        theme.palette.mode === "dark" ? "#101010" : "grey.100",
                      color: (theme) =>
                        theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                      border: "1px solid",
                      borderColor: (theme) =>
                        theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                      borderRadius: 2,
                      fontSize: "1.6rem",
                      fontWeight: "700",
                      maxWidth: "80rem",
                      padding: "0.4rem 1.6rem",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(
                        `${ROUTES.establishments}/${establishmentData?.id}${ROUTES.display}`
                      )
                    }
                  >
                    {establishmentData?.name}
                  </Box>
                </Tooltip>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.row}>
                <div className={`h2-bold-18 ${styles.label}`}>Indice:</div>
                <Tooltip
                  arrow
                  title={
                    <span className="btn-semibold-12 gray">
                      Ir a detalle del indice
                    </span>
                  }
                  classes={{
                    tooltip: styles.tooltip,
                    arrow: styles.tooltipArrow,
                  }}
                  placement="right"
                >
                  <Box
                    component="div"
                    sx={{
                      whiteSpace: "normal",
                      overflowX: "auto",
                      my: 2,
                      p: 1,
                      bgcolor: (theme) =>
                        theme.palette.mode === "dark" ? "#101010" : "grey.100",
                      color: (theme) =>
                        theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                      border: "1px solid",
                      borderColor: (theme) =>
                        theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                      borderRadius: 2,
                      fontSize: "1.6rem",
                      fontWeight: "700",
                      maxWidth: "80rem",
                      padding: "0.4rem 1.6rem",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(`${ROUTES.indexDetail}/${indexData.id}`)
                    }
                  >
                    {indexData?.name}
                  </Box>
                </Tooltip>
              </div>
              <div className={styles.row}>
                <div className={`h2-bold-18 ${styles.label}`}>Potrero:</div>
                <Box
                  component="div"
                  sx={{
                    whiteSpace: "normal",
                    overflowX: "auto",
                    my: 2,
                    p: 1,
                    bgcolor: (theme) =>
                      theme.palette.mode === "dark" ? "#101010" : "grey.100",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                    border: "1px solid",
                    borderColor: (theme) =>
                      theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                    borderRadius: 2,
                    fontSize: "1.6rem",
                    fontWeight: "700",
                    maxWidth: "80rem",
                    padding: "0.4rem 1.6rem",
                  }}
                >
                  {paddockData?.name}
                </Box>
              </div>
            </div>
          </div>
        )}
        <div className={styles.treeContainer}>
          <Card className={styles.card}>
            <MeasurmentTree
              establishmentId={establishmentId}
              paddockId={paddockId}
              measurementId={measurementId}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default DisplayMeasurement;
