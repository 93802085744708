import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Button, Checkbox } from '@mui/material/';
import { Icon } from '@iconify/react';
import EditIcon from '@iconify/icons-eva/edit-2-fill';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ZoomInIcon from '@iconify/icons-eva/maximize-outline';
import * as dayjs from 'dayjs';

import PageTitle from 'components/common/page-title';
import Table from 'components/table';
import { getIndexs } from 'api/gets';
import SERVICES from 'constants/services';
import ROUTES from 'routes/routesPaths';

import styles from './styles.module.scss';

const BackOffice = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(SERVICES.indexes, () => getIndexs());

  const columns = [
    {
      field: 'ver',
      headerName: 'VER',
      width: 20,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          navigate(`${ROUTES.indexDetail}/${params.id}`);
        };
        return (
          <div className={styles.buttonContainer}>
            <Button variant="outlined" onClick={(e) => onClick(e)}>
              <Icon icon={ZoomInIcon} width={23} height={23} />
            </Button>
          </div>
        );
      },
    },
    {
      field: 'editar',
      headerName: 'EDITAR',
      width: 20,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          navigate(`${ROUTES.editIndex}/${params.id}`);
        };
        return (
          <div className={styles.buttonContainer}>
            <Button variant="outlined" onClick={(e) => onClick(e)}>
              <Icon icon={EditIcon} width={23} height={23} />
            </Button>
          </div>
        );
      },
    },
    { field: 'id', headerName: 'ID', width: 70, flex: 1, align: 'center', headerAlign: 'center' },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 200,
      flex: 6,
    },
    {
      field: 'description',
      headerName: 'Descripción',
      width: 450,
      flex: 6,
    },
    {
      field: 'date',
      headerName: 'Fecha',
      width: 130,
      flex: 2,
      type: 'date',
      valueFormatter: (params) => {
        return dayjs(params.value).format('DD/MM/YYYY');
      },
    },
    {
      field: 'active',
      headerName: 'Activo',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: (params) => {
        return (
          <div className={styles.tableRow}>
            <Checkbox
              checked={params.value}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              disabled={true}
            />
          </div>
        );
      },
    },
  ];

  const rows = useMemo(
    () =>
      data?.indexes &&
      data?.indexes?.map(({ id, name, description, createdAt, active }) => ({
        id,
        name,
        description,
        date: createdAt,
        active,
      })),
    [data]
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleWrapper}>
          <PageTitle text="Indices" titleContainerClassName={styles.title} />
        </div>
        <div className={styles.content}>
          <Table rows={rows} columns={columns} cardClassName={styles.table} loading={isLoading} />
          <Fab
            color="primary"
            aria-label="add"
            className={styles.addButton}
            onClick={() => navigate(ROUTES.createIndex)}
          >
            <AddIcon label="agregar" sx={{ fontSize: 35 }}>
              Agregar
            </AddIcon>
          </Fab>
        </div>
      </div>
    </>
  );
};

export default BackOffice;
