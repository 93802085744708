import { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageTitle from 'components/common/page-title';
import IndexForm from 'components/backoffice/index-form';
import ROUTES from 'routes/routesPaths';
import { createIndex } from 'api/posts';
import SERVICES from 'constants/services';

import toastSyles from 'styles/common/toast.module.scss';
import styles from './styles.module.scss';

const CreateIndex = () => {
  const [formValues, setFormValues] = useState({ name: '', description: '', observation: '' });

  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    doCreate();
  };

  const queryClient = useQueryClient();

  const {
    mutate: doCreate,
    isLoading,
    isFetching,
  } = useMutation(() => createIndex(formValues), {
    onError: () => {
      toast('Error: Something went wrong', { type: 'error', className: toastSyles.error });
    },
    onSuccess: () => {
      toast('Indice creado', {
        type: 'success',
        className: toastSyles.success,
        onClose: () => navigate(ROUTES.backoffice),
      });
      queryClient.invalidateQueries(SERVICES.indexes);
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <PageTitle text="Crear indice" isCenterText={true} />
      </div>

      <div className={styles.content}>
        <IndexForm
          formValues={formValues}
          setFormValues={setFormValues}
          onSubmit={onSubmit}
          isLoading={isLoading || isFetching}
        />
      </div>
    </div>
  );
};

export default CreateIndex;
