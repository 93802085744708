import httpClient from 'httpClient';
import SERVICES from 'constants/services';

export const editIndex = async (indexId, indexValues) => {
  const { data } = await httpClient.put(`${SERVICES.indexes}/${indexId}`, { ...indexValues });
  return data;
};

export const editIndexParameter = async (indexId, parameterId, values) => {
  const { data } = await httpClient.put(
    `${SERVICES.indexes}/${indexId}${SERVICES.parameters}/${parameterId}`,
    {
      ...values,
    }
  );
  return data;
};

export const deleteEntityData = async (indexId, entityId, dataTypeId) => {
  const { data } = await httpClient.delete(
    entityId
      ? `${SERVICES.indexes}/${indexId}${SERVICES.nodes}/${entityId}${SERVICES.dataTypes}/${dataTypeId}`
      : `${SERVICES.indexes}/${indexId}${SERVICES.dataTypes}/${dataTypeId}`
  );
  return data;
};

export const deleteEntity = async (indexId, entityId) => {
  const { data } = await httpClient.delete(
    `${SERVICES.indexes}/${indexId}${SERVICES.nodes}/${entityId}`
  );
  return data;
};

export const deleteCustomValue = async (indexId, enumId, valueId) => {
  const { data } = await httpClient.delete(
    `${SERVICES.indexes}/${indexId}${SERVICES.customEnums}/${enumId}${SERVICES.customValues}/${valueId}`
  );
  return data;
};
