// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__izIDZ {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  justify-content: flex-start;
  text-align: center;
}

.styles_buttons__yMhKc {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-direction: column;
  min-width: 20rem;
  margin: 2rem 0 2rem 0;
  gap: 1rem;
}

.styles_button__TlZ5o {
  width: 80%;
  border-color: rgba(31, 185, 11, 0.87) !important;
}

.styles_card__noTve {
  padding-top: 1rem;
  background-color: rgba(31, 185, 11, 0.137) !important;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}`, "",{"version":3,"sources":["webpack://./src/pages/users-management/roles/styles.module.scss","webpack://./src/styles/common/mixins.scss"],"names":[],"mappings":"AAEA;ECDE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;EDAA,sBAAA;EACA,gBAAA;EACA,2BAAA;EACA,kBAAA;AAEF;;AACA;ECTE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;EDQA,sBAAA;EACA,gBAAA;EACA,qBAAA;EACA,SAAA;AAKF;;AAFA;EACE,UAAA;EACA,gDAAA;AAKF;;AAHA;EACE,iBAAA;EACA,qDAAA;EACA,8EAAA;AAMF","sourcesContent":["@import 'styles/common/mixins';\n\n.container {\n  @include flex-center();\n  flex-direction: column;\n  min-height: 100%;\n  justify-content: flex-start;\n  text-align: center;\n}\n\n.buttons {\n  @include flex-center();\n  flex-direction: column;\n  min-width: 20rem;\n  margin: 2rem 0 2rem 0;\n  gap: 1rem;\n}\n\n.button {\n  width: 80%;\n  border-color: rgba(31, 185, 11, 0.87) !important;\n}\n.card {\n  padding-top: 1rem;\n  background-color: rgba(31, 185, 11, 0.137) !important;\n  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);\n}\n","@mixin flex-center($flex-direction: row) {\n  align-items: center;\n  display: flex;\n  flex-direction: $flex-direction;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__izIDZ`,
	"buttons": `styles_buttons__yMhKc`,
	"button": `styles_button__TlZ5o`,
	"card": `styles_card__noTve`
};
export default ___CSS_LOADER_EXPORT___;
