import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { AppBar, Toolbar, IconButton, Stack, Box, Avatar } from '@mui/material';
// components
import { MHidden } from 'components/@material-extend';
import userIcon from '@iconify/icons-eva/person-fill';
import AccountOptions from 'components/account-options';
import { useSession } from 'hooks';

// ----------------------------------------------------------------------

const APPBAR_HEIGHT = 60;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 1,
  height: APPBAR_HEIGHT,
  backdropFilter: 'blur(6px)',
  width: '100%',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.neutral, 0.92),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_HEIGHT,
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar, openSideBar, onLogout }) {
  const [optionsOpen, setOptionsOpen] = useState(false);

  const onClickAccount = () => {
    setOptionsOpen(true);
  };
  const anchorRef = useRef(null);
  const {
    session: { user },
  } = useSession();

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} width={30} height={30} />
          </IconButton>
        </MHidden>
        <MHidden width="lgDown">
          {!openSideBar && (
            <>
              <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                <Icon icon={menu2Fill} width={30} height={30} />
              </IconButton>
            </>
          )}
        </MHidden>
        {!openSideBar && (
          <>
            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <IconButton
                ref={anchorRef}
                onClick={onClickAccount}
                sx={{
                  padding: 0,
                  width: 44,
                  height: 44,
                  ...(optionsOpen && {
                    '&:before': {
                      zIndex: 1,
                      content: "''",
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      position: 'absolute',
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                    },
                  }),
                }}
              >
                <Avatar src={user?.profileImg}>
                  <Icon icon={userIcon} width={30} height={30} />
                </Avatar>
              </IconButton>

              <AccountOptions
                setOpen={setOptionsOpen}
                open={optionsOpen}
                anchorRef={anchorRef}
                onLogout={onLogout}
              />
            </Stack>
          </>
        )}
      </ToolbarStyle>
    </RootStyle>
  );
}
