import { Icon } from '@iconify/react';
import PlantIcon from '@iconify-icons/la/pagelines';

import home from '@iconify/icons-eva/home-outline';
import settings from '@iconify/icons-eva/settings-outline';
import ROUTES from 'routes/routesPaths';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={25} height={25} />;

const getSidebarConfig = (indexId) =>
  [
    {
      title: 'Establecimientos',
      path: ROUTES.establishments,
      icon: getIcon(home),
    },
    {
      title: 'Administrar indices',
      path: ROUTES.backoffice,
      icon: getIcon(settings),
    },
  ].concat(
    indexId
      ? {
          title: `Administrar indice ${indexId}`,
          path: `${ROUTES.indexDetail}/${indexId}`,
          icon: getIcon(PlantIcon),
          children: [
            {
              title: 'Ver',
              path: `${ROUTES.indexDetail}/${indexId}`,
            },
            {
              title: 'Editar',
              path: `${ROUTES.editIndex}/${indexId}`,
            },
            {
              title: 'Entidades',
              path: `${ROUTES.indexDetail}/${indexId}${ROUTES.entities}`,
            },
            {
              title: 'Tipos de dato',
              path: `${ROUTES.indexDetail}/${indexId}${ROUTES.dataTypes}`,
            },
            {
              title: 'Enumerados',
              path: `${ROUTES.indexDetail}/${indexId}${ROUTES.enums}`,
            },
            {
              title: 'Parámetros',
              path: `${ROUTES.indexDetail}/${indexId}${ROUTES.params}`,
            },
          ],
        }
      : []
  );

export default getSidebarConfig;
