import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticated: false,
  user: null,
  info: {},
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.user = payload;
      state.authenticated = true;
    },
    signUp: (state, { payload }) => {
      state.user = payload;
    },
    logout: () => initialState,
  },
});

export const { login, signUp, logout } = sessionSlice.actions;

export default sessionSlice.reducer;
