import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { shape, string, func, bool } from 'prop-types';
import { TextField, Button, Link, Fab, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import cn from 'classnames/bind';
import AddIcon from '@mui/icons-material/Add';
import { Icon } from '@iconify/react';
import EditIcon from '@iconify/icons-eva/edit-2-fill';
import ZoomInIcon from '@iconify/icons-eva/maximize-outline';

import ROUTES from 'routes/routesPaths';
import Table from 'components/table';

import styles from './styles.module.scss';

const style = cn.bind(styles);

const EstablishmentForm = ({
  formValues,
  setFormValues,
  onSubmit,
  isLoading,
  enabled,
  establishmentId,
}) => {
  const onChange = useCallback(
    (inputKey, e) => {
      setFormValues({ ...formValues, [inputKey]: e.target.value });
    },
    [formValues, setFormValues]
  );

  const buttonStyle = style({
    button: true,
    buttonFullWidth: enabled,
  });

  const disabled = isLoading || !enabled;
  const navigate = useNavigate();

  const columns = [
    {
      field: 'ver',
      headerName: 'VER MEDIDAS',
      width: 20,
      flex: 2,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation();
          navigate(
            `${ROUTES.establishments}/${establishmentId}${ROUTES.paddocks}/${params.row.id}${ROUTES.measurements}`
          );
        };
        return (
          <div className={styles.buttonContainer}>
            <Button variant="outlined" onClick={(e) => onClick(e)}>
              <Icon icon={ZoomInIcon} width={23} height={23} />
            </Button>
          </div>
        );
      },
    },
    ...(enabled
      ? [
          {
            field: 'edit',
            headerName: 'EDITAR',
            headerAlign: 'center',
            flex: 1,
            align: 'center',
            renderCell: (params) => {
              return (
                <div className={styles.buttonContainer}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      navigate(
                        `${ROUTES.establishments}/${establishmentId}${ROUTES.paddocks}/${params.row.id}${ROUTES.edit}`
                      )
                    }
                  >
                    <Icon icon={EditIcon} width={23} height={23} />
                  </Button>
                </div>
              );
            },
          },
        ]
      : []),
    {
      field: 'name',
      headerName: 'Nombre',
      headerAlign: 'left',
      flex: 5,
      align: 'left',
    },
    {
      field: 'description',
      headerName: 'Descripción',
      headerAlign: 'left',
      flex: 5,
      align: 'left',
    },
    {
      field: 'potentialVeg',
      headerName: 'Vegetación potencial',
      headerAlign: 'center',
      flex: 3,
      align: 'center',
    },
    {
      field: 'hasZr',
      headerName: 'Zona Riparia',
      headerAlign: 'center',
      flex: 2,
      align: 'center',
      renderCell: (params) => {
        return (
          <div className={styles.tableRow}>
            <Checkbox
              checked={params.value}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              disabled={true}
            />
          </div>
        );
      },
    },
  ];
  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <TextField
        id="name"
        label="Nombre"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        className={styles.input}
        required
        value={formValues.name || ''}
        onChange={(e) => onChange('name', e)}
        disabled={disabled}
      />
      <div className={styles.inputsRow}>
        <TextField
          id="producer"
          label="Productor"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          className={styles.input}
          required
          value={formValues.producer || ''}
          onChange={(e) => onChange('producer', e)}
          disabled={disabled}
        />
        <TextField
          id="department"
          label="Departamento"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          className={styles.input}
          required
          value={formValues.department || ''}
          onChange={(e) => onChange('department', e)}
          disabled={disabled}
        />
        <TextField
          id="location"
          label="Localidad"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          className={styles.input}
          required
          value={formValues.location || ''}
          onChange={(e) => onChange('location', e)}
          disabled={disabled}
        />
      </div>
      <div className={styles.tableWrapper}>
        <div className={styles.row}>
          <span className={`h2-bold-24 ${styles.tableTitle}`}>Potreros:</span>
          {enabled && (
            <Fab
              color="primary"
              aria-label="add"
              className={styles.addButton}
              onClick={() =>
                navigate(`${ROUTES.establishments}/${establishmentId}${ROUTES.createPaddock}`)
              }
            >
              <AddIcon label="agregar" sx={{ fontSize: 35 }}>
                Agregar
              </AddIcon>
            </Fab>
          )}
        </div>
        <Table
          rows={formValues?.paddocks || []}
          columns={columns}
          cardClassName={styles.table}
          loading={isLoading}
          pageSize={4}
          rowsPerPageOptions={[4]}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          component={Link}
          variant="contained"
          size="large"
          disabled={isLoading}
          className={buttonStyle}
          href={ROUTES.establishments}
        >
          <span className="h3-bold-18 white">Volver</span>
        </Button>
        {enabled && (
          <LoadingButton
            variant="contained"
            size="large"
            className={styles.button}
            loading={isLoading}
            type="submit"
          >
            {!isLoading && <span className="h3-bold-18 white">Confirmar</span>}
          </LoadingButton>
        )}
      </div>
    </form>
  );
};

EstablishmentForm.propTypes = {
  formValues: shape({
    name: string,
    description: string,
    observation: string,
  }).isRequired,
  setFormValues: func.isRequired,
  onSubmit: func.isRequired,
  isLoading: bool,
};

export default EstablishmentForm;
