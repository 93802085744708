import { useMemo } from 'react';
import { useQuery } from 'react-query';
import Autocomplete from '@mui/material/Autocomplete';
import dataIcon from '@iconify-icons/la/database';
import { CircularProgress, TextField } from '@mui/material';
import { string, func, shape, object, bool, number } from 'prop-types';

import Confirm from 'components/common/modal-content/confirm';
import Modal from 'components/common/modal';
import { getDataTypes } from 'api/gets';
import SERVICES from 'constants/services';

import colors from 'styles/common/constants.module.scss';
import styles from './styles.module.scss';

const DataTypesModal = ({
  indexId,
  leftButton,
  rightButton,
  isLoading,
  open,
  setOpen,
  setNewDataType,
  newDataType,
}) => {
  const { data: dataTypes, isLoading: isLoadingDataTypes } = useQuery(
    [SERVICES.datatypes, indexId],
    () => getDataTypes(indexId)
  );

  const dataTypesOptions = useMemo(
    () =>
      dataTypes?.dataTypeList?.map(({ id, name }) => ({
        id,
        label: name,
      })),
    [dataTypes]
  );

  return (
    <Modal
      showModal={open}
      setShowModal={setOpen}
      containerClassName={styles.modalContainer}
      closeIconColor={colors.white}
    >
      <Confirm
        title="Agregar Dato"
        titleClassName={styles.modalTitle}
        icon={dataIcon}
        headerClassName={styles.modalHeader}
        leftButton={leftButton}
        rightButton={{ ...rightButton, disabled: !newDataType }}
        isLoading={isLoading}
      >
        <div className={styles.modalContent}>
          <div className={styles.inputWrapper}>
            <span className={`h3-bold-18 ${styles.inputLabel}`}>Tipo de dato :</span>
            <Autocomplete
              id="combo-box-demo"
              loading={isLoadingDataTypes}
              value={newDataType}
              onChange={(_, newValue) => {
                setNewDataType(newValue);
              }}
              disabled={isLoading}
              options={dataTypesOptions || []}
              sx={{ maxWidth: 500, width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={params.value}
                  placeholder="Tipo de dato"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingDataTypes ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
      </Confirm>
    </Modal>
  );
};

DataTypesModal.propTypes = {
  indexId: number,
  leftButton: shape({
    label: string,
    onClick: func,
  }),
  rightButton: shape({
    label: string,
    onClick: func,
  }),
  isLoading: bool,
  open: bool,
  setOpen: func,
  setNewDataType: func,
  newDataType: object,
};

export default DataTypesModal;
