import { DataGrid } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid';
import { Card } from '@mui/material/';
import { array } from 'prop-types';

import styles from './style.module.scss';

const Table = ({ rows = [], columns, loading, cardClassName, pageSize, ...props }) => {
  return (
    <Card className={`${styles.card} ${cardClassName}`}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize || 8}
        rowsPerPageOptions={[8]}
        disableSelectionOnClick={true}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        disableColumnSelector
        getRowClassName={() => styles.row}
        classes={{ root: styles.table }}
        className={styles.table}
        loading={loading}
        {...props}
      />
    </Card>
  );
};

Table.prototype = {
  rows: array,
  columns: array.isRequired,
};

export default Table;
