import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { shape, string, func, bool } from 'prop-types';
import { TextField, Button, Select, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import cn from 'classnames/bind';

import CustomEnumsTable from 'components/tables/custom-enums';
import DataTypesTable from 'components/tables/data-types';
import { getDataTypes, getCustomEnums } from 'api/gets';
import SERVICES from 'constants/services';
import { dataValues } from 'constants';

import styles from './styles.module.scss';

const datagridSx = {
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'none',
  },
  '& .MuiDataGrid-checkboxInput': {
    transform: 'scale(1.5)',
  },
};

const style = cn.bind(styles);

const DataTypeForm = ({ formValues, setFormValues, onLeftClick, onSubmit, isLoading, indexId }) => {
  const onChange = useCallback(
    (inputKey, e) => {
      setFormValues({ ...formValues, [inputKey]: e.target.value });
    },
    [formValues, setFormValues]
  );

  const { data: dataTypes, isLoading: isLoadingDataTypes } = useQuery(
    [SERVICES.datatypes, indexId],
    () => getDataTypes(indexId)
  );

  const { data: customEnums, isLoading: isLoadingCustomEnums } = useQuery(
    [SERVICES.customEnums, indexId],
    () => getCustomEnums(indexId)
  );

  const [selectionModel, setSelectionModel] = useState([]);

  const inputsStyle = style({
    inputs: true,
    inputsRow: formValues?.dataValue === 'LIST' || formValues?.dataValue === 'ENUM',
  });

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={inputsStyle}>
        <div className={styles.inputRow}>
          <InputLabel id="demo-simple-select-label" className={styles.inputLabel}>
            Nombre
          </InputLabel>
          <TextField
            id="name"
            variant="outlined"
            className={styles.input}
            required
            placeholder="Nombre"
            value={formValues.name || ''}
            onChange={(e) => onChange('name', e)}
            disabled={isLoading}
          />
        </div>
        <div className={styles.inputRow}>
          <InputLabel id="demo-simple-select-label" className={styles.inputLabel}>
            Descripción
          </InputLabel>
          <TextField
            id="description"
            variant="outlined"
            className={styles.input}
            required
            placeholder="Descripción"
            value={formValues.description || ''}
            onChange={(e) => onChange('description', e)}
            disabled={isLoading}
          />
        </div>
        <div className={styles.inputRow}>
          <InputLabel id="dataValues" className={styles.inputLabel}>
            Tipo de valor
          </InputLabel>
          <Select
            labelId="dataValues"
            id="dataValues"
            value={formValues?.dataValue}
            onChange={(e) => onChange('dataValue', e)}
            className={styles.input}
          >
            {dataValues.map(({ id, name }) => (
              <MenuItem key={id} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      {formValues?.dataValue === 'LIST' && (
        <div className={styles.tableWrapper}>
          <InputLabel id="demo-simple-select-label" className={styles.inputLabel}>
            Tipo de elementos:
          </InputLabel>
          <DataTypesTable
            rows={dataTypes?.dataTypeList}
            indexId={indexId}
            isLoading={isLoadingDataTypes}
            tableClassName={styles.table}
            sx={datagridSx}
            pageSize={4}
            rowsPerPageOptions={[4]}
            isAddEnabled={false}
            isDeleteEnabled={false}
            checkboxSelection={true}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(
                newSelectionModel.length ? [newSelectionModel[newSelectionModel.length - 1]] : []
              );
              setFormValues({ ...formValues, typeOfList: selectionModel[0], enumTypeId: null });
            }}
            selectionModel={selectionModel}
          />
        </div>
      )}

      {formValues?.dataValue === 'ENUM' && (
        <div className={styles.tableWrapper}>
          <InputLabel id="demo-simple-select-label" className={styles.inputLabel}>
            Enumerado:
          </InputLabel>
          <CustomEnumsTable
            rows={customEnums || []}
            indexId={indexId}
            isLoading={isLoadingCustomEnums}
            tableClassName={styles.table}
            sx={datagridSx}
            pageSize={4}
            rowsPerPageOptions={[4]}
            isAddEnabled={false}
            isDeleteEnabled={false}
            checkboxSelection={true}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(
                newSelectionModel.length ? [newSelectionModel[newSelectionModel.length - 1]] : []
              );
              setFormValues({ ...formValues, enumTypeId: selectionModel[0], typeOfList: null });
            }}
            selectionModel={selectionModel}
          />
        </div>
      )}

      <div className={styles.buttons}>
        <Button
          variant="contained"
          size="large"
          disabled={isLoading}
          className={styles.button}
          onClick={onLeftClick}
        >
          <span className="h3-bold-18 white">Volver</span>
        </Button>

        <LoadingButton
          variant="contained"
          size="large"
          className={styles.button}
          loading={isLoading}
          type="submit"
        >
          {!isLoading && <span className="h3-bold-18 white">Confirmar</span>}
        </LoadingButton>
      </div>
    </form>
  );
};

DataTypeForm.propTypes = {
  formValues: shape({
    name: string,
    description: string,
  }).isRequired,
  setFormValues: func.isRequired,
  onSubmit: func.isRequired,
  isLoading: bool,
};

export default DataTypeForm;
