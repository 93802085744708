import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import routesPaths from './routesPaths';
// Pages
import HomePage from 'pages/home';
import Login from 'pages/login';
import IndexDetail from 'pages/backoffice/index/detail';
import NotFoundPage from 'pages/not-found';
import BackOffice from 'pages/backoffice';
import Users from 'pages/users-management/users';
import Roles from 'pages/users-management/roles';
import IndexParameters from 'pages/backoffice/index/parameters';
import IndexEntities from 'pages/backoffice/index/entities';
import CreateIndex from 'pages/backoffice/index/create';
import EditIndex from 'pages/backoffice/index/edit';
import EditEntity from 'pages/backoffice/index/edit-entity';
import CreateEntity from 'pages/backoffice/index/create-entity';
import IndexDataTypes from 'pages/backoffice/index/data-types';
import CreateDataType from 'pages/backoffice/index/create-data-type';
import DisplayEstablishment from 'pages/establishment/display';
import EditEstablishment from 'pages/establishment/edit';
import DisplayMeasurement from 'pages/display-measurement';
import EditPaddock from 'pages/edit-paddock';
import Measurements from 'pages/measurements';
import IndexEnums from 'pages/backoffice/index/enums';
import EditEnum from 'pages/backoffice/index/edit-enum';
import CreateEnum from 'pages/backoffice/index/create-enum';
import CreatePaddock from 'pages/create-paddock';
import ExpiredSession from 'pages/expired-session';

//Layout
import DashboardLayout from 'layouts/dashboard';

import { useSession } from 'hooks';

export default function Router() {
  const { session } = useSession();
  return useRoutes([
    {
      path: routesPaths.root,
      element: !!session ? <DashboardLayout /> : <Navigate to={routesPaths.login} />,
      children: [
        { element: <Navigate to={routesPaths.establishments} replace /> },
        { path: routesPaths.establishments, element: <HomePage /> },
        {
          path: `${routesPaths.indexDetail}/:indexId${routesPaths.createEnum}`,
          element: <CreateEnum />,
        },
        {
          path: `${routesPaths.indexDetail}/:indexId${routesPaths.enums}/:enumId${routesPaths.edit}`,
          element: <EditEnum />,
        },
        {
          path: `${routesPaths.indexDetail}/:indexId${routesPaths.enums}`,
          element: <IndexEnums />,
        },
        {
          path: `${routesPaths.establishments}/:establishmentId${routesPaths.paddocks}/:paddockId${routesPaths.measurements}`,
          element: <Measurements />,
        },
        {
          path: `${routesPaths.establishments}/:establishmentId${routesPaths.paddocks}/:paddockId${routesPaths.displayMeasurement}/:measurementId`,
          element: <DisplayMeasurement />,
        },
        {
          path: `${routesPaths.establishments}/:establishmentId${routesPaths.display}`,
          element: <DisplayEstablishment />,
        },
        {
          path: `${routesPaths.establishments}/:establishmentId${routesPaths.edit}`,
          element: <EditEstablishment />,
        },
        {
          path: `${routesPaths.establishments}/:establishmentId${routesPaths.paddocks}/:paddockId${routesPaths.edit}`,
          element: <EditPaddock />,
        },
        {
          path: `${routesPaths.establishments}/:establishmentId${routesPaths.createPaddock}`,
          element: <CreatePaddock />,
        },
        {
          path: routesPaths.backoffice,
          element: <BackOffice />,
        },
        {
          path: routesPaths.createIndex,
          element: <CreateIndex />,
        },
        {
          path: `${routesPaths.editIndex}/:indexId`,
          element: <EditIndex />,
        },
        {
          path: `${routesPaths.indexDetail}/:indexId${routesPaths.editEntity}/:entityId`,
          element: <EditEntity />,
        },
        {
          path: `${routesPaths.indexDetail}/:indexId${routesPaths.createEntity}`,
          element: <CreateEntity />,
        },
        {
          path: `${routesPaths.indexDetail}/:indexId`,
          element: <IndexDetail />,
        },
        {
          path: `${routesPaths.indexDetail}/:indexId${routesPaths.params}`,
          element: <IndexParameters />,
        },
        {
          path: `${routesPaths.indexDetail}/:indexId${routesPaths.entities}`,
          element: <IndexEntities />,
        },
        {
          path: `${routesPaths.indexDetail}/:indexId${routesPaths.dataTypes}`,
          element: <IndexDataTypes />,
        },
        {
          path: `${routesPaths.indexDetail}/:indexId${routesPaths.createDataType}`,
          element: <CreateDataType />,
        },
        { path: routesPaths.users, element: <Users /> },
        { path: routesPaths.roles, element: <Roles /> },
        {
          path: '/',
          element: !session ? <Login /> : <Navigate to={routesPaths.establishments} replace />,
        },
      ],
    },
    {
      path: routesPaths.expiredSession,
      element: !session ? <ExpiredSession /> : <NotFoundPage />,
    },
    {
      path: '/',
      element: !session ? <Login /> : <Navigate to={routesPaths.establishments} replace />,
      children: [{ path: routesPaths.login, element: <Login /> }],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);
}
