// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_row__GvL2J {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.styles_addButton__KWLQe {
  height: 5rem;
  margin-bottom: 2rem;
  width: 5rem;
}

.styles_tableTitle__pTlO2 {
  margin-bottom: 1rem;
  margin-top: auto;
}

.styles_tableRow__d74pe {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.styles_dataTypeColumn__L81Ti {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.styles_valuesList__tCsZK {
  display: flex;
  gap: 1rem;
  height: 4rem;
  margin-left: 2rem;
  width: 14rem;
}`, "",{"version":3,"sources":["webpack://./src/components/tables/custom-enums/styles.module.scss"],"names":[],"mappings":"AAIA;EACI,aAAA;EACA,8BAAA;EACA,WAAA;AAQJ;;AALA;EACI,YAAA;EACA,mBAAA;EACA,WAAA;AAQJ;;AALA;EACI,mBAAA;EACA,gBAAA;AAQJ;;AALA;EACI,mBAAA;EACA,aAAA;EACA,SAAA;AAQJ;;AALA;EACI,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,WAAA;AAQJ;;AALA;EACI,aAAA;EACA,SAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;AAQJ","sourcesContent":["\n@import 'styles/common/mixins';\n@import 'styles/common/constants.module';\n\n.row {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.addButton {\n    height: 5rem;\n    margin-bottom: 2rem;\n    width: 5rem;\n}\n\n.tableTitle {\n    margin-bottom: 1rem;\n    margin-top: auto;\n}\n\n.tableRow {\n    align-items: center;\n    display: flex;\n    gap: 1rem;\n}\n\n.dataTypeColumn {\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.valuesList{\n    display: flex;\n    gap:1rem;\n    height: 4rem;\n    margin-left: 2rem;\n    width: 14rem;\n}\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"row": `styles_row__GvL2J`,
	"addButton": `styles_addButton__KWLQe`,
	"tableTitle": `styles_tableTitle__pTlO2`,
	"tableRow": `styles_tableRow__d74pe`,
	"dataTypeColumn": `styles_dataTypeColumn__L81Ti`,
	"valuesList": `styles_valuesList__tCsZK`
};
export default ___CSS_LOADER_EXPORT___;
