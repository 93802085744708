// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_form__brhR8 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2rem;
  width: 100%;
}

.styles_input__fdUCu {
  max-width: 30rem;
  width: 100%;
}

.styles_inputLarge__6Tb34 {
  width: 100%;
}

.styles_button__Kf8kU {
  max-width: 12rem;
  width: 100%;
}

.styles_button__Kf8kU {
  max-width: 100%;
  width: 100%;
}

.styles_buttonContainer__THIfl {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.styles_buttons__zICOC {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  max-width: 30rem;
  width: 100%;
}

.styles_row__0LOBM {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.styles_inputsRow__iG16J {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  width: 100%;
}

.styles_tableWrapper__sinue {
  max-width: 120rem;
  text-align: left;
  width: 100%;
}

.styles_table__xQa49 {
  height: 100%;
  height: 35rem;
  max-width: 120rem;
  width: 100%;
}

.styles_addButton__pjgH3 {
  height: 5rem;
  margin-bottom: 2rem;
  width: 5rem;
}

.styles_tableTitle__iJUci {
  margin-bottom: 1rem;
  margin-top: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/establishment-form/styles.module.scss","webpack://./src/styles/common/mixins.scss"],"names":[],"mappings":"AAGA;ECFE,mBAAA;EACA,aAAA;EACA,sBDCqB;ECArB,uBAAA;EDCA,aAAA;EACA,WAAA;AAYF;;AATA;EACE,gBAAA;EACA,WAAA;AAYF;;AATA;EACE,WAAA;AAYF;;AAVA;EACE,gBAAA;EACA,WAAA;AAaF;;AAVA;EACE,eAAA;EACA,WAAA;AAaF;;AAXA;ECzBE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;EDwBA,WAAA;AAiBF;;AAdA;EC9BE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;ED6BA,SAAA;EACA,gBAAA;EACA,WAAA;AAoBF;;AAjBA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;AAoBF;;AAjBA;EC3CE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;ED0CA,SAAA;EACA,WAAA;AAuBF;;AApBA;EACE,iBAAA;EACA,gBAAA;EACA,WAAA;AAuBF;;AApBA;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,WAAA;AAuBF;;AApBA;EACE,YAAA;EACA,mBAAA;EACA,WAAA;AAuBF;;AApBA;EACE,mBAAA;EACA,gBAAA;AAuBF","sourcesContent":["@import 'styles/common/mixins';\n@import 'styles/common/constants.module';\n\n.form {\n  @include flex-center(column);\n  row-gap: 2rem;\n  width: 100%;\n}\n\n.input {\n  max-width: 30rem;\n  width: 100%;\n}\n\n.inputLarge {\n  width: 100%;\n}\n.button {\n  max-width: 12rem;\n  width: 100%;\n}\n\n.button {\n  max-width: 100%;\n  width: 100%;\n}\n.buttonContainer{\n  @include flex-center();\n  width: 100%\n}\n\n.buttons {\n  @include flex-center();\n  gap: 2rem;\n  max-width: 30rem;\n  width: 100%;\n}\n\n.row {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.inputsRow{\n  @include flex-center();\n  gap:2rem;\n  width: 100%;\n}\n\n.tableWrapper{\n  max-width: 120rem;\n  text-align: left;\n  width: 100%;\n}\n\n.table {\n  height: 100%;\n  height: 35rem;\n  max-width: 120rem;\n  width: 100%;\n}\n\n.addButton {\n  height: 5rem;\n  margin-bottom: 2rem;\n  width: 5rem;\n}\n\n.tableTitle {\n  margin-bottom: 1rem;\n  margin-top: auto;\n}\n\n","@mixin flex-center($flex-direction: row) {\n  align-items: center;\n  display: flex;\n  flex-direction: $flex-direction;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"form": `styles_form__brhR8`,
	"input": `styles_input__fdUCu`,
	"inputLarge": `styles_inputLarge__6Tb34`,
	"button": `styles_button__Kf8kU`,
	"buttonContainer": `styles_buttonContainer__THIfl`,
	"buttons": `styles_buttons__zICOC`,
	"row": `styles_row__0LOBM`,
	"inputsRow": `styles_inputsRow__iG16J`,
	"tableWrapper": `styles_tableWrapper__sinue`,
	"table": `styles_table__xQa49`,
	"addButton": `styles_addButton__pjgH3`,
	"tableTitle": `styles_tableTitle__iJUci`
};
export default ___CSS_LOADER_EXPORT___;
