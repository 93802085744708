import cn from 'classnames/bind';
import { Icon } from '@iconify/react';
import Button from '@mui/material/Button';

import styles from './styles.module.scss';

const style = cn.bind(styles);

const PageTitle = ({
  text,
  icon,
  iconClassName,
  isCenterText = false,
  titleContainerClassName = '',
  showHr = true,
  onClick,
}) => {
  const rowStyle = style({
    row: true,
    centerText: isCenterText,
  });

  return (
    <div className={`${styles.titleContainer} ${titleContainerClassName}`}>
      {onClick ? (
        <Button variant="outlined" onClick={onClick}>
          <div className={rowStyle}>
            {icon && <Icon icon={icon} height="45" width="45" className={iconClassName} />}
            <span className={`h1-bold-32 ${styles.title}`}>{text}</span>
          </div>
        </Button>
      ) : (
        <div className={rowStyle}>
          {icon && <Icon icon={icon} height="45" width="45" className={iconClassName} />}
          <span className={`h1-bold-32 ${styles.title}`}>{text}</span>
        </div>
      )}

      {showHr && <hr className={styles.divider} />}
    </div>
  );
};

export default PageTitle;
