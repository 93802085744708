import httpClient from 'httpClient';
import SERVICES from 'constants/services';

export const editIndex = async (indexId, indexValues) => {
  const { data } = await httpClient.post(`${SERVICES.indexes}/${indexId}`, { ...indexValues });
  return data;
};

export const editIndexParameter = async (indexId, parameterId, values) => {
  const { data } = await httpClient.post(
    `${SERVICES.indexes}/${indexId}${SERVICES.parameters}/${parameterId}`,
    {
      ...values,
    }
  );
  return data;
};

export const addDataToEntity = async (indexId, entityId, dataTypeId) => {
  const { data } = await httpClient.post(
    `${SERVICES.indexes}/${indexId}${SERVICES.nodes}/${entityId}${SERVICES.dataTypes}/${dataTypeId}`
  );
  return data;
};

export const editIndexEntity = async (indexId, entityId, values) => {
  const { data } = await httpClient.post(
    `${SERVICES.indexes}/${indexId}${SERVICES.nodes}/${entityId}`,
    {
      ...values,
    }
  );
  return data;
};

export const editPaddock = async (establishmentId, paddockId, values) => {
  const { data } = await httpClient.post(
    `${SERVICES.establishments}/${establishmentId}${SERVICES.paddocks}/${paddockId}`,
    {
      ...values,
    }
  );
  return data;
};

export const editEstablishment = async (establishmentId, values) => {
  const { data } = await httpClient.post(`${SERVICES.establishments}/${establishmentId}`, {
    ...values,
  });
  return data;
};

export const editCustomValue = async (indexId, enumId, valueId, valueData) => {
  const { data } = await httpClient.post(
    `${SERVICES.indexes}/${indexId}${SERVICES.customEnums}/${enumId}${SERVICES.customValues}/${valueId}`,
    {
      ...valueData,
    }
  );
  return data;
};

export const editCustomEnum = async (indexId, enumId, enumData) => {
  const { data } = await httpClient.post(
    `${SERVICES.indexes}/${indexId}${SERVICES.customEnums}/${enumId}`,
    {
      ...enumData,
    }
  );
  return data;
};
