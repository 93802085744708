import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

import PageTitle from 'components/common/page-title';
import PaddockForm from 'components/paddock-form';
import SERVICES from 'constants/services';
import { getEstablishment } from 'api/gets';
import { createPaddock } from 'api/posts';
import ROUTES from 'routes/routesPaths';

import toastSyles from 'styles/common/toast.module.scss';
import styles from './styles.module.scss';

const CreatePaddock = () => {
  const { establishmentId } = useParams();

  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    potentialVeg: {},
    hasZr: false,
    surface: 0,
  });

  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    doCreatePaddock();
  };

  const { data: establishmentData, isLoading: isLoadingEstablishment } = useQuery(
    [SERVICES.establishments, establishmentId],
    () => getEstablishment(establishmentId)
  );

  const queryClient = useQueryClient();

  // TODO - Make paddock post works
  const { mutate: doCreatePaddock, isLoading: isLoadingCreatePaddock } = useMutation(
    () =>
      createPaddock(establishmentId, {
        ...formValues,
        potentialVegId: formValues?.potentialVeg?.id,
        surface: parseFloat(formValues?.surface),
      }),

    {
      onError: (error) => {
        toast(`Error: ${error?.message || 'Something went wrong'}`, {
          type: 'error',
          className: toastSyles.error,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([SERVICES.establishments, establishmentId]);
        toast('Potrero creado', {
          type: 'success',
          className: toastSyles.success,
          onClose: () => navigate(`${ROUTES.establishments}/${establishmentId}${ROUTES.edit}`),
        });
      },
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        {isLoadingEstablishment ? (
          <CircularProgress color="success" />
        ) : (
          <PageTitle
            text={`Editar Establecimiento : ${establishmentData?.name}`}
            isCenterText={true}
            showHr={false}
          />
        )}
      </div>
      <div className={styles.subTitleWrapper}>
        <PageTitle text="Crear potrero" isCenterText={true} />
      </div>
      <div className={styles.content}>
        <PaddockForm
          formValues={formValues}
          setFormValues={setFormValues}
          onSubmit={onSubmit}
          isLoading={isLoadingCreatePaddock}
          enabled={true}
          establishmentId={establishmentId}
          indexId={establishmentData?.indexId}
        />
      </div>
    </div>
  );
};

export default CreatePaddock;
