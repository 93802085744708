import { Button, Box, Divider, MenuItem, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import logOut from '@iconify/icons-eva/log-out-outline';
import { Link } from 'react-router-dom';

import { useSession } from 'hooks';
import MenuPopover from 'components/menu-popover';

const MENU_OPTIONS = [];

const AccountOptions = ({ open, setOpen, anchorRef, onLogout }) => {
  const { session } = useSession();

  const handleClose = () => {
    setOpen(false);
  };
  const { user } = session;
  return (
    <MenuPopover
      open={open}
      onClose={handleClose}
      anchorEl={anchorRef?.current}
      sx={{ width: 220 }}
    >
      <Box sx={{ my: 1.5, px: 2.5 }}>
        <Typography variant="subtitle1" noWrap>
          {user?.name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {user?.email}
        </Typography>
      </Box>

      <Divider sx={{ my: 1 }} />

      {MENU_OPTIONS.map((option) => (
        <MenuItem
          key={option.label}
          to={option.linkTo}
          component={Link}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={option.icon}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />

          {option.label}
        </MenuItem>
      ))}

      <Box sx={{ p: 2, pt: 1.5 }}>
        <Button fullWidth color="inherit" variant="outlined" onClick={onLogout}>
          <Icon icon={logOut} width={28} height={28} color="red" style={{ marginRight: '1rem' }} />
          Cerrar Sesión
        </Button>
      </Box>
    </MenuPopover>
  );
};

export default AccountOptions;
