import { useState } from 'react';
import { string, array, bool, number } from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import dataIcon from '@iconify-icons/la/database';
import ZoomInIcon from '@iconify/icons-eva/maximize-outline';
import TrashIcon from '@iconify/icons-eva/trash-outline';
import { MenuItem, Menu, Fab, Button, IconButton } from '@mui/material';

import SERVICES from 'constants/services';
import { addDataToEntity } from 'api/puts';
import { deleteEntityData } from 'api/deletes';
import Table from 'components/table';
import Confirm from 'components/common/modal-content/confirm';
import Modal from 'components/common/modal';
import DataTypesModal from 'components/data-types-modal';

import colors from 'styles/common/constants.module.scss';
import toastSyles from 'styles/common/toast.module.scss';
import styles from './styles.module.scss';

const DataTypesTable = ({
  rows,
  isLoading,
  indexId,
  entityId,
  tableClassName,
  isAddEnabled = true,
  isDeleteEnabled = true,
  ...props
}) => {
  const [newDataType, setNewDataType] = useState(null);
  const [deleteDataType, setDeleteDataType] = useState(null);
  const [openDataTypesModal, setOpenDataTypesModal] = useState(false);
  const [openDeleteDataTypeModal, setOpenDeleteDataTypeModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const openValuesList = Boolean(anchorEl);

  const handleValuesClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setActiveItem(id);
  };
  const handleValuesClose = () => {
    setAnchorEl(null);
    setActiveItem(null);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
      headerAlign: 'left',
      flex: 2,
      align: 'left',
      renderCell: (params) => {
        return (
          <div className={styles.tableRow}>
            <Icon icon={dataIcon} width={23} height={23} />
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'dataType',
      headerName: 'Tipo de dato',
      headerAlign: 'left',
      align: 'left',
      flex: 3,
      renderCell: (params) => {
        const row = params.row;
        const enumType = row?.enumType;
        const listType = row?.typeOfList;
        const listEnumType = row?.typeOfList?.enumType;
        const list = listEnumType || enumType;
        return (
          <div className={styles.dataTypeColumn}>
            <div className={styles.dataTypeLabel}>
              <span className="tag-regular-12">{row.name}</span>
              <span className="tag2-bold-12">
                {' '}
                {listType?.dataType
                  ? ` (${params.value} ${listType?.dataType && `(${listType?.dataType})`} )`
                  : ` (${params.value})`}
              </span>
            </div>

            {list && (
              <>
                <Button
                  id="basic-button"
                  aria-controls={openValuesList && activeItem === row.id ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openValuesList && activeItem === row.id ? 'true' : undefined}
                  onClick={(event) => handleValuesClick(event, row.id)}
                  className={styles.dataTypeSelect}
                  variant="outlined"
                >
                  <Icon icon={ZoomInIcon} width={23} height={23} />
                  Ver valores
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openValuesList && activeItem === row.id}
                  onClose={handleValuesClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {list?.valuesList.map(({ id, name, value }) => (
                    <MenuItem key={id} value={value} onClick={handleValuesClose}>
                      {name}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </div>
        );
      },
    },
    ...(isDeleteEnabled
      ? [
          {
            field: 'delete',
            headerName: 'Borrar',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              return (
                <IconButton
                  onClick={() => {
                    setDeleteDataType(params.row);
                    setOpenDeleteDataTypeModal(true);
                  }}
                >
                  <DeleteIcon sx={{ fontSize: 30, color: colors?.red || 'red' }} />
                </IconButton>
              );
            },
          },
        ]
      : []),
  ];

  const queryClient = useQueryClient();

  const { mutate: doDeleteDataType, isLoading: isLoadingDeleteDataType } = useMutation(
    () => deleteEntityData(indexId, entityId, deleteDataType?.id),
    {
      onError: (error) => {
        toast(`Error: ${error?.message || 'Something went wrong'}`, {
          type: 'error',
          className: toastSyles.error,
        });
      },
      onSuccess: (newData) => {
        queryClient.setQueryData(
          entityId ? [SERVICES.nodes, entityId] : [SERVICES.dataTypes, indexId],
          newData
        );
        toast('Dato borrado', { type: 'success', className: toastSyles.success });
        setOpenDeleteDataTypeModal(false);
      },
    }
  );

  const { mutate: doPutDataType, isLoading: isLoadingPutDataType } = useMutation(
    () => addDataToEntity(indexId, entityId, newDataType?.id),
    {
      onError: (error) => {
        toast(`Error: ${error?.message || 'Something went wrong'}`, {
          type: 'error',
          className: toastSyles.error,
        });
      },
      onSuccess: (newEntityData) => {
        queryClient.setQueryData([SERVICES.nodes, entityId], newEntityData);
        toast('Dato agregado', { type: 'success', className: toastSyles.success });
        setOpenDataTypesModal(false);
      },
    }
  );

  const dataTypesModal = {
    leftButton: { label: 'Cancelar', onClick: () => setOpenDataTypesModal(false) },
    rightButton: {
      label: 'Agregar',
      onClick: () => {
        doPutDataType();
      },
    },
  };

  const deleteDataTypeModal = {
    leftButton: {
      label: 'Cancelar',
      className: styles.deleteModalButton,
      onClick: () => setOpenDeleteDataTypeModal(false),
    },
    rightButton: {
      label: 'Borrar',
      className: styles.deleteModalButton,
      onClick: () => {
        doDeleteDataType();
      },
    },
  };

  return (
    <>
      {isAddEnabled && (
        <div className={styles.row}>
          <span className={`h2-bold-24 ${styles.tableTitle}`}>Datos:</span>
          <Fab
            color="primary"
            aria-label="add"
            className={styles.addButton}
            onClick={() => setOpenDataTypesModal(true)}
          >
            <AddIcon label="agregar" sx={{ fontSize: 35 }}>
              Agregar
            </AddIcon>
          </Fab>
        </div>
      )}
      <Table
        rows={rows}
        columns={columns}
        cardClassName={tableClassName}
        loading={isLoading}
        {...props}
      />
      <Modal
        showModal={openDeleteDataTypeModal}
        setShowModal={setOpenDeleteDataTypeModal}
        containerClassName={styles.deleteModalContainer}
        closeIconColor={colors.white}
      >
        <Confirm
          title="Borrar Dato"
          titleClassName={styles.modalTitle}
          icon={TrashIcon}
          headerClassName={styles.deleteModalHeader}
          leftButton={deleteDataTypeModal.leftButton}
          rightButton={deleteDataTypeModal.rightButton}
          isLoading={isLoadingDeleteDataType}
        >
          <div className={styles.deleteModalContent}>
            <span className="h2-bold-18">Desea borrar el dato ?</span>
            <div>
              <span className="h2-bold-18">Dato : </span>
              <span>{deleteDataType?.name}</span>
            </div>
          </div>
        </Confirm>
      </Modal>
      <DataTypesModal
        indexId={indexId}
        leftButton={dataTypesModal.leftButton}
        rightButton={dataTypesModal.rightButton}
        isLoading={isLoadingPutDataType}
        open={openDataTypesModal}
        setOpen={setOpenDataTypesModal}
        setNewDataType={setNewDataType}
        newDataType={newDataType}
      />
    </>
  );
};

DataTypesTable.propTypes = {
  rows: array,
  isLoading: bool,
  indexId: number,
  entityId: number,
  tableClassName: string,
};

export default DataTypesTable;
