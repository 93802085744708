import { useState, useMemo, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import PlantIcon from '@iconify-icons/la/pagelines';
import EditIcon from '@iconify/icons-eva/edit-2-fill';
import SettingsIcon from '@iconify/icons-eva/settings-2-outline';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import {
  Button,
  Fab,
  TextField,
  Pagination,
  Tooltip,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import PageTitle from 'components/common/page-title';
import SERVICES from 'constants/services';
import { getIndexParameters, getIndex } from 'api/gets';
import { editIndexParameter } from 'api/puts';
import { createIndexParameter } from 'api/posts';
import Modal from 'components/common/modal';
import Confirm from 'components/common/modal-content/confirm';
import Table from 'components/table';
import ParameterForm from 'components/backoffice/parameter-form';
import ROUTES from 'routes/routesPaths';

import toastSyles from 'styles/common/toast.module.scss';
import colors from 'styles/common/constants.module.scss';
import styles from './styles.module.scss';

const IndexParameters = () => {
  const { indexId } = useParams();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const formInitialValues = useMemo(() => ({ name: '', value: '' }), []);

  const [formValues, setFormValues] = useState(formInitialValues);
  const [paramId, setParamId] = useState(null);
  const [tablePage, setTablePage] = useState(1);
  const [filterName, setFilterName] = useState('');
  const [filterInputValue, setFilterInputValue] = useState('');

  const queryParams = { pageNumber: tablePage, pageSize: 6, likeName: filterName };

  const { data: indexParameters, isLoading } = useQuery(
    [`${SERVICES.indexes}${SERVICES.parameters}`, indexId, queryParams],
    () => getIndexParameters(indexId, queryParams)
  );

  const { data: indexData } = useQuery([SERVICES.indexes, indexId], () => getIndex(indexId));

  const queryClient = useQueryClient();

  const { mutate: doEdit, isLoading: isLoadingEdit } = useMutation(
    () => editIndexParameter(indexId, paramId, formValues),
    {
      onError: () => {
        toast('Error: Something went wrong', { type: 'error', className: toastSyles.error });
      },
      onSuccess: () => {
        toast('Parámetro editado', {
          type: 'success',
          className: toastSyles.success,
        });
        queryClient.invalidateQueries([
          `${SERVICES.indexes}${SERVICES.parameters}`,
          indexId,
          queryParams,
        ]);
      },
    }
  );

  const { mutate: doCreate, isLoading: isLoadingCreate } = useMutation(
    () => createIndexParameter(indexId, formValues),
    {
      onError: () => {
        toast('Error: Something went wrong', { type: 'error', className: toastSyles.error });
      },
      onSuccess: () => {
        toast('Parámetro creado', {
          type: 'success',
          className: toastSyles.success,
          onClose: () => setOpenModal(false),
        });
        setFormValues(formInitialValues);
        setOpenModal(false);
        queryClient.invalidateQueries([
          `${SERVICES.indexes}${SERVICES.parameters}`,
          indexId,
          queryParams,
        ]);
      },
    }
  );

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (paramId) {
      doEdit();
    } else {
      doCreate();
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
      headerAlign: 'left',
      flex: 9,
      align: 'left',
      renderCell: (params) => {
        return (
          <Tooltip
            title={<span className="btn-semibold-12 gray">{params.value}</span>}
            classes={{ tooltip: styles.tooltip }}
            placement="top-start"
          >
            <div className={styles.paramRow}>
              <Icon icon={SettingsIcon} width={23} height={23} className={styles.icon} />
              {params.value}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Descripción',
      headerAlign: 'left',
      align: 'left',
      flex: 8,
      renderCell: (params) => {
        return (
          <Tooltip
            title={<span className="btn-semibold-12 gray">{params.value}</span>}
            classes={{ tooltip: styles.tooltip }}
            placement="top"
          >
            <span>{params.value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'value',
      headerName: 'Valor',
      headerAlign: 'center',
      align: 'center',
      flex: 2,
    },
    {
      field: 'edit',
      headerName: '',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className={styles.buttonContainer}>
            <Button
              variant="outlined"
              onClick={() => {
                const row = params.row;
                setParamId(row.id);
                setFormValues({
                  name: row.name,
                  description: row.description,
                  value: row.value,
                });
                setOpenModal(true);
              }}
            >
              <Icon icon={EditIcon} width={23} height={23} />
            </Button>
          </div>
        );
      },
    },
  ];

  const isLoadingForm = isLoadingEdit || isLoadingCreate;

  const CustomPagination = useCallback(
    () => (
      <>
        <Pagination
          component="div"
          color="primary"
          variant="outlined"
          shape="rounded"
          page={tablePage}
          count={indexParameters?.totalPages || 0}
          onChange={(_, value) => {
            setTablePage(value);
          }}
        />
      </>
    ),
    [tablePage, setTablePage, indexParameters]
  );

  return (
    <>
      <div className={styles.container}>
        <Tooltip
          title={<span className="btn-semibold-12 gray">Ir a detalle del indice</span>}
          classes={{ tooltip: styles.titleTooltip, arrow: styles.tooltipArrow }}
          placement="right"
          arrow
        >
          <div className={styles.titleWrapper}>
            <PageTitle
              text={indexData?.name}
              icon={PlantIcon}
              iconClassName={styles.icon}
              isCenterText={true}
              showHr={false}
              titleContainerClassName={styles.titleContainer}
              onClick={() => navigate(`${ROUTES.indexDetail}/${indexId}`)}
            />
          </div>
        </Tooltip>
        <div className={styles.subtitleContainer}>
          <div className={styles.subtitle}>
            <span className="h2-bold-24">Listado de parámetros</span>
            <hr className={styles.divider} />
          </div>
        </div>

        <div className={styles.filterRow}>
          <div className={styles.filter}>
            <TextField
              id="filterName"
              label="Nombre"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              placeholder="Nombre"
              value={filterInputValue}
              onChange={(e) => setFilterInputValue(e.target.value)}
              disabled={isLoading}
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon sx={{ fontSize: 25 }} />
                    </InputAdornment>
                  </>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading && filterInputValue ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <IconButton
                        onClick={() => {
                          setFilterInputValue('');
                          setFilterName('');
                          setTablePage(1);
                        }}
                        disabled={isLoading}
                      >
                        <CloseIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                classes: {
                  input: styles.input,
                },
              }}
            />
            <Button
              variant="contained"
              size="large"
              disabled={isLoading}
              className={styles.filterButton}
              onClick={() => {
                setFilterName(filterInputValue);
                setTablePage(1);
              }}
            >
              <div className={styles.buttonRow}>
                <span className="h3-bold-18 white">Filtrar</span>
              </div>
            </Button>
          </div>
        </div>
        <div className={styles.table}>
          <Table
            rows={indexParameters?.parameters || []}
            columns={columns}
            loading={isLoading}
            onPageChange={(page) => setTablePage(page)}
            pageSize={6}
            components={{
              Pagination: CustomPagination,
            }}
          />
        </div>
      </div>
      <Fab
        color="primary"
        aria-label="add"
        className={styles.addButton}
        onClick={() => {
          setFormValues(formInitialValues);
          setParamId(null);
          setOpenModal(true);
        }}
      >
        <AddIcon label="agregar" sx={{ fontSize: 35 }}>
          Agregar
        </AddIcon>
      </Fab>
      <Modal
        showModal={openModal}
        setShowModal={setOpenModal}
        containerClassName={styles.modalContainer}
        closeIconColor={colors.white || 'white'}
        onClose={() => {
          setFormValues(formInitialValues);
          setParamId(null);
        }}
      >
        <Confirm
          title={paramId ? 'Modificar' : 'Crear parametro'}
          titleClassName={styles.modalTitle}
          descriptionClassName="h2-bold-18"
          icon={SettingsIcon}
          headerClassName={styles.modalHeader}
        >
          <div className={styles.modalContent}>
            <ParameterForm
              formValues={formValues}
              setFormValues={setFormValues}
              isLoading={isLoadingForm}
              onSubmit={onSubmitForm}
              onLeftClick={() => {
                setFormValues(formInitialValues);
                setOpenModal(false);
                setParamId(null);
              }}
            />
          </div>
        </Confirm>
      </Modal>
    </>
  );
};

export default IndexParameters;
