// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__onqli {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 1rem 3rem;
  width: 100%;
}

.styles_table__dwYR2 {
  height: 100%;
  max-height: 50rem;
  max-width: 100rem;
  width: 100%;
}

.styles_addButton__YTPNE {
  height: 7rem;
  position: absolute;
  right: 3rem;
  top: 9rem;
  width: 7rem;
}

.styles_titleWrapper__yrLiA {
  max-width: 100rem;
  width: 100%;
}

.styles_title__4ggQb {
  max-width: 25rem;
  text-align: left;
}

.styles_content__axke0 {
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
}

.styles_buttonContainer__-JK6O {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.styles_button__jEhuZ {
  display: flex;
  font-size: 2rem;
  gap: 1rem;
  height: 7rem;
  margin-top: 2rem;
  max-width: 40rem;
  width: 100%;
}

.styles_item__m0pKq {
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/backoffice/styles.module.scss","webpack://./src/styles/common/mixins.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,2BAAA;EACA,kBAAA;EACA,WAAA;AASF;;AANA;EACE,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,WAAA;AASF;;AANA;EACE,YAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;AASF;;AANA;EACE,iBAAA;EACA,WAAA;AASF;;AANA;EACE,gBAAA;EACA,gBAAA;AASF;;AANA;EACE,aAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;AASF;;AANA;EC9CE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;ED6CA,WAAA;AAYF;;AATA;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AAYF;;AATA;EACE,sBAAA;AAYF","sourcesContent":["@import 'styles/common/mixins';\n@import 'styles/common/constants.module';\n\n.container {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: flex-start;\n  padding: 1rem 3rem;\n  width: 100%;\n}\n\n.table {\n  height: 100%;\n  max-height: 50rem;\n  max-width: 100rem;\n  width: 100%;\n}\n\n.addButton {\n  height: 7rem;\n  position: absolute;\n  right: 3rem;\n  top: 9rem;\n  width: 7rem;\n}\n\n.titleWrapper {\n  max-width: 100rem;\n  width: 100%;\n}\n\n.title {\n  max-width: 25rem;\n  text-align: left;\n}\n\n.content {\n  display: flex;\n  flex-grow: 1;\n  height: 100%;\n  justify-content: center;\n  margin-top: 2rem;\n  width: 100%;\n}\n\n.buttonContainer {\n  @include flex-center();\n  width: 100%;\n}\n\n.button {\n  display: flex;\n  font-size: 2rem;\n  gap: 1rem;\n  height: 7rem;\n  margin-top: 2rem;\n  max-width: 40rem;\n  width: 100%;\n}\n\n.item {\n  width: 100% !important;\n}\n","@mixin flex-center($flex-direction: row) {\n  align-items: center;\n  display: flex;\n  flex-direction: $flex-direction;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"container": `styles_container__onqli`,
	"table": `styles_table__dwYR2`,
	"addButton": `styles_addButton__YTPNE`,
	"titleWrapper": `styles_titleWrapper__yrLiA`,
	"title": `styles_title__4ggQb`,
	"content": `styles_content__axke0`,
	"buttonContainer": `styles_buttonContainer__-JK6O`,
	"button": `styles_button__jEhuZ`,
	"item": `styles_item__m0pKq`
};
export default ___CSS_LOADER_EXPORT___;
