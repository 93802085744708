import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

import PageTitle from 'components/common/page-title';
import IndexForm from 'components/backoffice/index-form';
import ROUTES from 'routes/routesPaths';
import SERVICES from 'constants/services';
import { editIndex } from 'api/puts';
import { getIndex } from 'api/gets';

import toastSyles from 'styles/common/toast.module.scss';
import styles from './styles.module.scss';

const EditIndex = () => {
  const navigate = useNavigate();
  const { indexId } = useParams();

  const queryClient = useQueryClient();
  const indexCacheData = queryClient.getQueryData([SERVICES.indexes, indexId]);

  useEffect(() => {
    indexCacheData && setFormValues({ ...indexCacheData });
  }, [indexCacheData]);

  const [formValues, setFormValues] = useState({ name: '', description: '', observation: '' });

  const {
    data: indexData,
    isLoading: isLoadingIndex,
    isFetching: isFetchingIndex,
  } = useQuery([SERVICES.indexes, indexId], () => getIndex(indexId), {
    onSuccess: () => setFormValues({ ...indexData }),
  });

  const [doEdit, setDoEdit] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setDoEdit(true);
  };

  const { isLoading: isLoadingEdit, isFetching: isFetchingEdit } = useQuery(
    SERVICES.index,
    () => editIndex(indexId, formValues),
    {
      enabled: doEdit,
      onError: () => {
        setDoEdit(false);
        toast('Error: Something went wrong', { type: 'error', className: toastSyles.error });
      },
      onSuccess: () => {
        setDoEdit(false);
        toast('Indice editado', {
          type: 'success',
          className: toastSyles.success,
          onClose: () => navigate(ROUTES.backoffice),
        });
      },
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <PageTitle text="Editar indice" isCenterText={true} />
      </div>

      <div className={styles.content}>
        {!!(isFetchingIndex || isLoadingIndex) ? (
          <CircularProgress color="success" />
        ) : (
          <IndexForm
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onSubmit}
            isLoading={isLoadingEdit || isFetchingEdit}
          />
        )}
      </div>
    </div>
  );
};

export default EditIndex;
