import { useCallback } from 'react';
import { shape, string, func, bool, number } from 'prop-types';
import { TextField, Button, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import EnumValuesTable from 'components/tables/enum-values';

import styles from './styles.module.scss';

const EnumForm = ({
  formValues,
  setFormValues,
  onLeftClick,
  onSubmit,
  isLoading,
  indexId,
  enumId,
  isValuesEnabled = true,
}) => {
  const onChange = useCallback(
    (inputKey, e) => {
      setFormValues({ ...formValues, [inputKey]: e.target.value });
    },
    [formValues, setFormValues]
  );

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={styles.inputs}>
        <div className={styles.inputRow}>
          <InputLabel id="demo-simple-select-label" className={styles.inputLabel}>
            Nombre
          </InputLabel>
          <TextField
            id="name"
            variant="outlined"
            className={styles.input}
            required
            placeholder="Nombre"
            value={formValues?.name || ''}
            onChange={(e) => onChange('name', e)}
            disabled={isLoading}
          />
        </div>
        <div className={styles.inputRow}>
          <InputLabel id="demo-simple-select-label" className={styles.inputLabel}>
            Descripción
          </InputLabel>
          <TextField
            id="description"
            variant="outlined"
            className={styles.input}
            required
            placeholder="Descripción"
            value={formValues?.description || ''}
            onChange={(e) => onChange('description', e)}
            disabled={isLoading}
          />
        </div>
        {isValuesEnabled && (
          <div className={styles.tableWrapper}>
            <EnumValuesTable
              rows={formValues?.valuesList}
              indexId={indexId}
              enumId={enumId}
              isLoading={isLoading}
              tableClassName={styles.table}
              pageSize={4}
              rowsPerPageOptions={[4]}
            />
          </div>
        )}
      </div>

      <div className={styles.buttons}>
        <Button
          variant="contained"
          size="large"
          disabled={isLoading}
          className={styles.button}
          onClick={onLeftClick}
          type="button"
        >
          <span className="h3-bold-18 white">Volver</span>
        </Button>

        <LoadingButton
          variant="contained"
          size="large"
          className={styles.button}
          loading={isLoading}
          type="submit"
        >
          {!isLoading && <span className="h3-bold-18 white">Confirmar</span>}
        </LoadingButton>
      </div>
    </form>
  );
};

EnumForm.propTypes = {
  formValues: shape({
    name: string,
    description: string,
  }).isRequired,
  setFormValues: func.isRequired,
  onSubmit: func.isRequired,
  isLoading: bool,
  indexId: number,
  enumId: number,
  isValuesEnabled: bool,
};

export default EnumForm;
