import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import PlantIcon from '@iconify-icons/la/pagelines';
import TrashIcon from '@iconify/icons-eva/trash-outline';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames/bind';
import { toast } from 'react-toastify';

import PageTitle from 'components/common/page-title';
import SERVICES from 'constants/services';
import { getIndex } from 'api/gets';
import CustomTreeView from 'components/common/custom-tree-view';
import ROUTES from 'routes/routesPaths';
import Modal from 'components/common/modal';
import Confirm from 'components/common/modal-content/confirm';
import { deleteEntity } from 'api/deletes';

import toastSyles from 'styles/common/toast.module.scss';
import colors from 'styles/common/constants.module.scss';
import styles from './styles.module.scss';

const style = cn.bind(styles);

const IndexEntities = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { indexId } = useParams();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteEntityData, setDeleteEntityData] = useState({});

  const { data: indexData, isLoading: isLoadingIndex } = useQuery([SERVICES.indexes, indexId], () =>
    getIndex(indexId)
  );

  const containerStyle = style({
    container: true,
    containerCenter: isLoadingIndex,
  });

  const { mutate: doDeleteEntity, isLoading: isLoadingDelete } = useMutation(
    () => deleteEntity(indexId, deleteEntityData.id),
    {
      onError: (error) => {
        toast(`Error: ${error?.message || 'Something went wrong'}`, {
          type: 'error',
          className: toastSyles.error,
        });
      },
      onSuccess: () => {
        toast('Entidad borrada', {
          type: 'success',
          className: toastSyles.success,
        });
        queryClient.invalidateQueries([SERVICES.indexes, indexId]);
      },
    }
  );

  const deleteModal = {
    leftButton: {
      label: 'Cancelar',
      onClick: () => setOpenDeleteModal(false),
      className: styles.deleteModalButton,
    },
    rightButton: {
      label: 'Borrar',
      onClick: () => {
        doDeleteEntity();
      },
      className: styles.deleteModalButton,
    },
  };

  const onDeleteClick = (e, entity) => {
    e.stopPropagation();
    setDeleteEntityData(entity);
    setOpenDeleteModal(true);
  };

  return (
    <>
      <div className={containerStyle}>
        {isLoadingIndex ? (
          <CircularProgress color="success" size={100} />
        ) : (
          <>
            <Tooltip
              title={<span className="btn-semibold-12 gray">Ir a detalle del indice</span>}
              classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
              placement="right"
              arrow
            >
              <div className={styles.titleWrapper}>
                <PageTitle
                  text={indexData?.name}
                  icon={PlantIcon}
                  iconClassName={styles.icon}
                  isCenterText={true}
                  showHr={false}
                  titleContainerClassName={styles.titleContainer}
                  onClick={() => navigate(`${ROUTES.indexDetail}/${indexId}`)}
                />
              </div>
            </Tooltip>
            <div className={styles.subtitle}>
              <PageTitle text="ENTIDADES" isCenterText={true} showHr={true} />
            </div>

            <div className={styles.treeContainer}>
              <Card className={styles.card}>
                <CustomTreeView indexId={indexId} deleteItemFunction={onDeleteClick} />
              </Card>
            </div>
          </>
        )}
        <Fab
          color="primary"
          aria-label="add"
          className={styles.addButton}
          onClick={() => navigate(`${ROUTES.indexDetail}/${indexId}${ROUTES.createEntity}`)}
        >
          <AddIcon label="agregar" sx={{ fontSize: 35 }}>
            Agregar
          </AddIcon>
        </Fab>
      </div>
      <Modal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        containerClassName={styles.deleteModalContainer}
        closeIconColor={colors.white}
      >
        <Confirm
          title="Borrar entidad"
          titleClassName={styles.modalTitle}
          icon={TrashIcon}
          headerClassName={styles.deleteModalHeader}
          leftButton={deleteModal.leftButton}
          rightButton={deleteModal.rightButton}
          isLoading={isLoadingDelete}
        >
          <div className={styles.deleteModalContent}>
            <span className="h2-bold-18">Desea borrar la entidad ?</span>
            <div>
              <span className="h2-bold-18">Entidad : </span>
              <span>{deleteEntityData?.name}</span>
            </div>
          </div>
        </Confirm>
      </Modal>
    </>
  );
};

export default IndexEntities;
