// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_divider__k7\\+iF {
  margin-top: 1rem;
}

.styles_titleContainer__l54IX {
  padding: 0.6rem;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}

.styles_row__jo0nF {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 3rem;
}

.styles_centerText__HL1i7 {
  justify-content: center;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/common/page-title/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,eAAA;EACA,WAAA;EACA,eAAA;AACF;;AAEA;EACE,uBAAA;EACA,kBAAA;AACF","sourcesContent":[".divider {\n  margin-top: 1rem;\n}\n\n.titleContainer {\n  padding: 0.6rem;\n  text-align: left;\n  text-transform: uppercase;\n  width: 100%;\n}\n\n.row {\n  align-items: center;\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  padding: 0 3rem;\n}\n\n.centerText {\n  justify-content: center;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `styles_divider__k7+iF`,
	"titleContainer": `styles_titleContainer__l54IX`,
	"row": `styles_row__jo0nF`,
	"centerText": `styles_centerText__HL1i7`
};
export default ___CSS_LOADER_EXPORT___;
