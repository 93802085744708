import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { func, object, bool, string, oneOf } from 'prop-types';
import SettingsIcon from '@iconify/icons-eva/settings-2-outline';
import TrashIcon from '@iconify/icons-eva/trash-outline';
import AddIcon from '@iconify/icons-eva/plus-square-outline';
import { TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import cn from 'classnames/bind';

import { deleteCustomValue } from 'api/deletes';
import { editCustomValue } from 'api/puts';
import { addCustomValue } from 'api/posts';
import Confirm from 'components/common/modal-content/confirm';
import Modal from 'components/common/modal';
import SERVICES from 'constants/services';
import { actionTypes } from 'constants';

import toastSyles from 'styles/common/toast.module.scss';
import colors from 'styles/common/constants.module.scss';
import styles from './styles.module.scss';

const style = cn.bind(styles);

const EnumValueModal = ({
  formValues,
  setFormValues,
  indexId,
  enumId,
  open,
  setOpen,
  actionType,
}) => {
  const [modalActionProps, setModalActionProps] = useState(null);

  const queryClient = useQueryClient();

  const { mutate: doEdit, isLoading: isLoadingEdit } = useMutation(
    () => editCustomValue(indexId, enumId, formValues?.id, formValues),
    {
      onError: () => {
        toast('Error: Something went wrong', { type: 'error', className: toastSyles.error });
      },
      onSuccess: () => {
        console.log(enumId);
        queryClient.invalidateQueries([SERVICES.customEnums, enumId]);
        toast('Valor editado', {
          type: 'success',
          className: toastSyles.success,
          onClose: () => setOpen(false),
        });
      },
    }
  );

  const { mutate: doDelete, isLoading: isLoadingDelete } = useMutation(
    () => deleteCustomValue(indexId, enumId, formValues?.id),
    {
      onError: () => {
        toast('Error: Something went wrong', { type: 'error', className: toastSyles.error });
      },
      onSuccess: () => {
        console.log(enumId);
        queryClient.invalidateQueries([SERVICES.customEnums, enumId]);
        setOpen(false);
        toast('Valor borrado', {
          type: 'success',
          className: toastSyles.success,
          onClose: () => setOpen(false),
        });
      },
    }
  );

  const { mutate: doAdd, isLoading: isLoadingAdd } = useMutation(
    () => addCustomValue(indexId, enumId, formValues),
    {
      onError: () => {
        toast('Error: Something went wrong', { type: 'error', className: toastSyles.error });
      },
      onSuccess: () => {
        console.log(enumId);
        queryClient.invalidateQueries([SERVICES.customEnums, enumId]);
        setOpen(false);
        toast('Valor agregado', {
          type: 'success',
          className: toastSyles.success,
          onClose: () => setOpen(false),
        });
      },
    }
  );

  const onChange = useCallback(
    (inputKey, e) => {
      setFormValues({ ...formValues, [inputKey]: e.target.value });
    },
    [formValues, setFormValues]
  );

  const isLoading = isLoadingEdit || isLoadingDelete || isLoadingAdd;

  const onSubmit = (e) => {
    e.stopPropagation();
    modalActionProps?.function();
  };

  const isDelete = actionType === actionTypes.delete;

  const modalHeaderStyle = style({
    modalHeader: true,
    modalHeaderDelete: isDelete,
  });

  const buttonStyle = style({
    button: true,
    buttonDelete: isDelete,
  });

  const modalContainerStyle = style({
    modalContainer: true,
    modalContainerDelete: isDelete,
  });

  const getModalProps = useCallback(
    (actionType) => {
      const modalProps = {};
      switch (actionType) {
        case actionTypes.delete:
          modalProps.title = 'Borrar valor';
          modalProps.icon = TrashIcon;
          modalProps.function = doDelete;
          break;
        case actionTypes.add:
          modalProps.title = 'Agregar valor';
          modalProps.icon = AddIcon;
          modalProps.function = doAdd;
          break;
        case actionTypes.edit:
          modalProps.title = 'Editar valor';
          modalProps.icon = SettingsIcon;
          modalProps.function = doEdit;
          break;
        default:
          modalProps.title = 'Editar valor';
      }
      return modalProps;
    },
    [doAdd, doEdit, doDelete]
  );

  useEffect(() => {
    setModalActionProps(getModalProps(actionType));
  }, [actionType, getModalProps]);

  return (
    <>
      <Modal
        showModal={open}
        setShowModal={setOpen}
        containerClassName={modalContainerStyle}
        closeIconColor={colors.white}
      >
        <Confirm
          title={modalActionProps?.title}
          titleClassName={styles.modalTitle}
          descriptionClassName="h2-bold-18"
          icon={modalActionProps?.icon}
          headerClassName={modalHeaderStyle}
        >
          <div className={styles.modalContent}>
            {isDelete && (
              <span className="h2-bold-18">¿ Desea borrar el valor del enumerado ?</span>
            )}
            <div className={styles.form}>
              <TextField
                id="name"
                label="Nombre"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                className={styles.input}
                placeholder="Nombre"
                value={formValues?.name || ''}
                onChange={(e) => onChange('name', e)}
                disabled={isLoading || isDelete}
              />
              <TextField
                id="description"
                label="Descripción"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                className={styles.input}
                placeholder="Descripción"
                value={formValues?.description || ''}
                onChange={(e) => onChange('description', e)}
                disabled={isLoading || isDelete}
              />
              <TextField
                id="value"
                label="Valor"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                className={styles.input}
                placeholder="Valor"
                value={formValues?.value || ''}
                onChange={(e) => onChange('value', e)}
                disabled={isLoading || isDelete}
              />
              <div className={styles.buttons}>
                <Button
                  variant="contained"
                  size="large"
                  disabled={isLoading}
                  className={buttonStyle}
                  onClick={() => setOpen(false)}
                >
                  <span className="h3-bold-18 white">Cancelar</span>
                </Button>
                <LoadingButton
                  variant="contained"
                  size="large"
                  className={buttonStyle}
                  loading={isLoading}
                  onClick={(e) => onSubmit(e)}
                >
                  {!isLoading && <span className="h3-bold-18 white">Confirmar</span>}
                </LoadingButton>
              </div>
            </div>
          </div>
        </Confirm>
      </Modal>
    </>
  );
};

EnumValueModal.propTypes = {
  initialValues: object,
  indexId: string,
  enumId: string,
  open: bool,
  setOpen: func,
  isEdit: bool,
  actionType: oneOf(Object.values(actionTypes)),
};

export default EnumValueModal;
