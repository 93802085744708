import { Button, Divider, Card } from '@mui/material';
import { string, func, shape, object, node } from 'prop-types';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';

import styles from './styles.module.scss';

const Confirm = ({
  title,
  titleClassName,
  description,
  descriptionClassName,
  icon,
  leftButton,
  rightButton,
  children,
  headerClassName = '',
  isLoading,
}) => {
  return (
    <div className={styles.container}>
      <Card className={`${styles.header} ${headerClassName}`}>
        <Icon icon={icon} width={28} height={28} color="white" className={styles.icon} />
        <h1 className={titleClassName}>{title}</h1>
      </Card>
      <div className={styles.content}>
        {children || <span className={descriptionClassName}>{description}</span>}
      </div>

      {leftButton && rightButton && (
        <div className={styles.footer}>
          <Divider className={styles.divider} />
          <div className={styles.buttons}>
            <Button
              variant="outlined"
              onClick={leftButton.onClick}
              disabled={isLoading || leftButton.disabled}
              className={`${styles.button} ${leftButton?.className}`}
            >
              {leftButton.label}
            </Button>
            <LoadingButton
              variant="outlined"
              onClick={rightButton.onClick}
              loading={isLoading}
              disabled={isLoading || rightButton.disabled}
              className={`${styles.button} ${rightButton?.className}`}
            >
              {!isLoading && rightButton.label}
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
};

Confirm.propTypes = {
  title: string,
  description: string,
  icon: object,
  leftButton: shape({
    label: string,
    onClick: func,
  }),
  children: node,
};

export default Confirm;
