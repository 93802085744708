import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import PlantIcon from '@iconify-icons/la/pagelines';
import AddIcon from '@mui/icons-material/Add';
import { Fab, Tooltip } from '@mui/material';

import PageTitle from 'components/common/page-title';
import SERVICES from 'constants/services';
import { getIndex, getCustomEnums } from 'api/gets';
import ROUTES from 'routes/routesPaths';
import CustomEnumsTable from 'components/tables/custom-enums';

import styles from './styles.module.scss';

const IndexEnums = () => {
  const { indexId } = useParams();
  const navigate = useNavigate();

  const { data: indexData } = useQuery([SERVICES.indexes, indexId], () => getIndex(indexId));

  const { data: customEnums, isLoading: isLoadingCustomEnums } = useQuery(
    [SERVICES.customEnums, indexId],
    () => getCustomEnums(indexId)
  );

  return (
    <>
      <div className={styles.container}>
        <Tooltip
          title={<span className="btn-semibold-12 gray">Ir a detalle del indice</span>}
          classes={{ tooltip: styles.titleTooltip, arrow: styles.tooltipArrow }}
          placement="right"
          arrow
        >
          <div className={styles.titleWrapper}>
            <PageTitle
              text={indexData?.name}
              icon={PlantIcon}
              iconClassName={styles.icon}
              isCenterText={true}
              showHr={false}
              titleContainerClassName={styles.titleContainer}
              onClick={() => navigate(`${ROUTES.indexDetail}/${indexId}`)}
            />
          </div>
        </Tooltip>
        <div className={styles.subtitleContainer}>
          <div className={styles.subtitle}>
            <span className="h2-bold-24">Enumerados</span>
            <hr className={styles.divider} />
          </div>
        </div>
        <div className={styles.table}>
          <CustomEnumsTable
            rows={customEnums || []}
            indexId={indexId}
            isLoading={isLoadingCustomEnums}
            tableClassName={styles.table}
            pageSize={7}
            rowsPerPageOptions={[7]}
            isAddEnabled={false}
            isDeleteEnabled={true}
          />
        </div>
      </div>
      <Fab
        color="primary"
        aria-label="add"
        className={styles.addButton}
        onClick={() => navigate(`./..${ROUTES.createEnum}`)}
      >
        <AddIcon label="agregar" sx={{ fontSize: 35 }}>
          Agregar
        </AddIcon>
      </Fab>
    </>
  );
};

export default IndexEnums;
