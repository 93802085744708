import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import ROUTES from 'routes/routesPaths';

const useSession = () => {
  const navigate = useNavigate();
  const [session, setSession] = useState(
    typeof Cookies.get('session') === 'string' && JSON.parse(Cookies.get('session'))
  );

  const cookieValue = Cookies.get('session');

  useEffect(() => {
    setSession(typeof cookieValue === 'string' && JSON.parse(cookieValue));
  }, [cookieValue]);

  const signIn = (name, email, imageUrl, tokenId) => {
    const session = {
      user: {
        name: name,
        email: email,
        role: 'Administrador',
        profileImg: imageUrl,
        accessToken: tokenId,
      },
    };
    Cookies.set('session', JSON.stringify(session), { path: ROUTES.root });
    navigate(ROUTES.backoffice);
  };

  const signOut = () => {
    Cookies.remove('session', { path: ROUTES.root });
    navigate('./');
  };
  return { session, signIn, signOut };
};

export default useSession;
