import * as React from 'react';
import { useMemo, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { Icon } from '@iconify/react';
import plusIcon from '@iconify/icons-eva/plus-square-outline';
import minusIcon from '@iconify/icons-eva/minus-square-outline';
import leafIcon from '@iconify-icons/la/leaf';
import { useQuery } from 'react-query';
import arrowIcon from '@iconify/icons-eva/arrow-forward-fill';
import { getMeasurement } from 'api/gets';
import SERVICES from 'constants/services';
//import { styled } from '@mui/material/styles';
import colors from 'styles/common/constants.module.scss';
import styles from './styles.module.scss';
import { makeStyles } from '@mui/styles';

const iconsDimensions = {
  width: 35,
  height: 35,
};

const ICON_DIMENSIONS = {
  width: 23,
  height: 23,
};

function MinusSquare() {
  return (
    <div className={styles.iconContainer}>
      <IconButton color="primary">
        <Icon icon={minusIcon} width={iconsDimensions.width} height={iconsDimensions.height} />
      </IconButton>
    </div>
  );
}

function PlusSquare() {
  return (
    <div className={styles.iconContainer}>
      <IconButton color="primary">
        <Icon icon={plusIcon} width={iconsDimensions.width} height={iconsDimensions.height} />
      </IconButton>
    </div>
  );
}

function LeafIcon() {
  return (
    <div className={styles.leafIconContainer} color="primary">
      <IconButton color="primary">
        <Icon icon={leafIcon} width={iconsDimensions.width} height={iconsDimensions.height} />
      </IconButton>
    </div>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

const useStyles = makeStyles({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed rgba(0, 0, 0, 0.4)`,
  },
});

const StyledTreeItem = (props) => {
  const classes = useStyles();
  return <TreeItem {...props} classes={{ iconContainer: classes.iconContainer, group: classes.group }} />;
};

const MeasurmentTree = ({
  establishmentId,
  paddockId,
  measurementId,
  defaultExpandedItems = ['1', '2', '3'],
  containerClassName = '',
}) => {
  const { data: measurementData, isLoading: isLoadingMeasurement } = useQuery(
    [SERVICES.measurements, establishmentId, paddockId],
    () => getMeasurement(establishmentId, paddockId, measurementId)
  );

  const generateTree = useCallback((items) => {
    return items.map((item) => {
      let color;
      const indexValue = item?.intermediateValue;
      if (typeof indexValue === 'number') {
        switch (true) {
          case indexValue <= 1:
            color = colors.ligthRed;
            break;
          case indexValue > 1 && indexValue <= 2:
            color = colors.orange;
            break;
          case indexValue > 2 && indexValue <= 3:
            color = colors.yellow;
            break;

          case indexValue > 3 && indexValue <= 4:
            color = colors.lightGreen;
            break;
          case indexValue > 4 && indexValue <= 5:
            color = colors.green;
            break;
          default:
            color = colors.black;
            break;
        }
      }

      const label = (
        <div className={styles.item}>
          <span className="h2-bold-18">{item?.node}</span>
          {!!indexValue && (
            <>
              <Icon
                icon={arrowIcon}
                width={ICON_DIMENSIONS.width}
                height={ICON_DIMENSIONS.height}
              />
              <div
                className={styles.valueTag}
                style={{
                  backgroundColor: color !== colors.black && color,
                }}
              >
                <span
                  className="h2-bold-18"
                  style={{
                    color:
                      color === colors.ligthRed || color === colors.orange
                        ? colors.white
                        : colors.black,
                  }}
                >
                  {typeof indexValue === 'number' && Math.round(indexValue) !== indexValue
                    ? indexValue.toFixed(4)
                    : indexValue}
                </span>
              </div>
            </>
          )}
        </div>
      );

      if (item?.children)
        return (
          <StyledTreeItem key={item.id} nodeId={String(item.id)} label={label}>
            {generateTree(item.children)}
          </StyledTreeItem>
        );
      else return <StyledTreeItem key={item.id} nodeId={item.id} label={item?.name} />;
    });
  }, []);

  const treeStructure = useMemo(
    () => measurementData?.root && generateTree([measurementData.root]),
    [measurementData, generateTree]
  );
  return (
    <>
      {isLoadingMeasurement ? (
        <CircularProgress />
      ) : (
        <TreeView
          aria-label="customized"
          defaultExpanded={defaultExpandedItems}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<LeafIcon />}
          className={styles.treeContainer}
        >
          {treeStructure}
        </TreeView>
      )}
    </>
  );
};

MeasurmentTree.propTypes = {
  defaultExpandedItems: PropTypes.array,
  containerClassName: PropTypes.string,
};

export default MeasurmentTree;
