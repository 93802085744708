// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_card__VF8-f {
  border: 1px solid #5be584;
  height: 100%;
}

.style_row__NmjZp {
  border-top: 1px solid #e0fae3;
  border-bottom: 1px solid #e0fae3;
}
.style_row__NmjZp:hover {
  background-color: transparent !important;
}

.style_ss-1pans1z-MuiDataGrid-virtualScroller__HjRMs {
  overflow-x: scroll !important;
}

.style_table__SxfXE {
  background-color: rgba(224, 250, 227, 0.631372549);
}`, "",{"version":3,"sources":["webpack://./src/components/table/style.module.scss","webpack://./src/styles/common/constants.module.scss"],"names":[],"mappings":"AAGA;EACE,yBAAA;EACA,YAAA;AASF;;AAPA;EAIE,6BAAA;EACA,gCAAA;AAOF;AAXE;EACE,wCAAA;AAaJ;;AAPA;EACE,6BAAA;AAUF;;AAPA;EACE,kDCNa;ADgBf","sourcesContent":["@import 'styles/common/mixins';\n@import 'styles/common/constants.module';\n\n.card {\n  border: 1px solid $light;\n  height: 100%;\n}\n.row {\n  &:hover {\n    background-color: transparent !important;\n  }\n  border-top: 1px solid $softGreen;\n  border-bottom: 1px solid $softGreen;\n}\n\n.ss-1pans1z-MuiDataGrid-virtualScroller {\n  overflow-x: scroll !important;\n}\n\n.table {\n  background-color: $softestGreen;\n}","// Fonts weights\n$normal: 400;\n$medium: 500;\n$semibold: 600;\n$bold: 700;\n// Colors\n$black: #000000;\n$gray: rgb(71, 69, 69);\n$lightGray: rgb(134, 129, 129);\n$athensGray: #F9FAFB;\n$white: #ffffff;\n$overlay: rgba(218, 211, 211, 0.75);\n$red:#8b0d0dad;\n//Green\n$softestGreen: #e0fae3a1;\n$softGreen: #e0fae3;\n$lighter: #c8facd;\n$light: #5be584;\n$dark: #007b55;\n$darker: #005249;\n$ligthRed:#c40707c8;\n$orange: #e69603;\n$yellow: #ffea00f5;\n$lightGreen: #b0d04f;\n$main: #00ab55;\n\n$contrastText: #fff;\n// z-index levels\n$z-index-top: 1300;\n$z-index-middle: 1200;\n$z-index-bottom: 100;\n\n:export {\n  white: $white;\n  black: $black;\n  red:$red;\n  ligthRed:$ligthRed;\n  lightGreen:$lightGreen;\n  green: $main;\n  yellow:$yellow;\n  orange:$orange;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"card": `style_card__VF8-f`,
	"row": `style_row__NmjZp`,
	"ss-1pans1z-MuiDataGrid-virtualScroller": `style_ss-1pans1z-MuiDataGrid-virtualScroller__HjRMs`,
	"table": `style_table__SxfXE`
};
export default ___CSS_LOADER_EXPORT___;
