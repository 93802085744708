// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__A2\\+xb {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 2rem;
  width: 100%;
}

.styles_titleIcon__0SKqP {
  color: #007b55;
  margin-right: 1rem;
}

.styles_table__QO19C {
  margin-top: 5rem;
  max-height: 50rem;
  max-width: 90rem;
  width: 100%;
}

.styles_buttonContainer__lQzKz {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/styles.module.scss","webpack://./src/styles/common/mixins.scss","webpack://./src/styles/common/constants.module.scss"],"names":[],"mappings":"AAGA;ECFE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;EDCA,sBAAA;EACA,YAAA;EACA,2BAAA;EACA,aAAA;EACA,WAAA;AAYF;;AATA;EACE,cEKK;EFJL,kBAAA;AAYF;;AARA;EACE,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;AAWF;;AARA;ECxBE,mBAAA;EACA,aAAA;EACA,mBAHkC;EAIlC,uBAAA;EDuBA,WAAA;AAcF","sourcesContent":["@import 'styles/common/mixins';\n@import 'styles/common/constants.module';\n\n.container {\n  @include flex-center();\n  flex-direction: column;\n  height: 100%;\n  justify-content: flex-start;\n  padding: 2rem;\n  width: 100%;\n}\n\n.titleIcon {\n  color: $dark;\n  margin-right: 1rem;\n}\n\n\n.table{\n  margin-top: 5rem;\n  max-height: 50rem;\n  max-width: 90rem;\n  width: 100%;\n}\n\n.buttonContainer {\n  @include flex-center();\n  width: 100%;\n}","@mixin flex-center($flex-direction: row) {\n  align-items: center;\n  display: flex;\n  flex-direction: $flex-direction;\n  justify-content: center;\n}\n","// Fonts weights\n$normal: 400;\n$medium: 500;\n$semibold: 600;\n$bold: 700;\n// Colors\n$black: #000000;\n$gray: rgb(71, 69, 69);\n$lightGray: rgb(134, 129, 129);\n$athensGray: #F9FAFB;\n$white: #ffffff;\n$overlay: rgba(218, 211, 211, 0.75);\n$red:#8b0d0dad;\n//Green\n$softestGreen: #e0fae3a1;\n$softGreen: #e0fae3;\n$lighter: #c8facd;\n$light: #5be584;\n$dark: #007b55;\n$darker: #005249;\n$ligthRed:#c40707c8;\n$orange: #e69603;\n$yellow: #ffea00f5;\n$lightGreen: #b0d04f;\n$main: #00ab55;\n\n$contrastText: #fff;\n// z-index levels\n$z-index-top: 1300;\n$z-index-middle: 1200;\n$z-index-bottom: 100;\n\n:export {\n  white: $white;\n  black: $black;\n  red:$red;\n  ligthRed:$ligthRed;\n  lightGreen:$lightGreen;\n  green: $main;\n  yellow:$yellow;\n  orange:$orange;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": `#ffffff`,
	"black": `#000000`,
	"red": `rgba(139, 13, 13, 0.6784313725)`,
	"ligthRed": `rgba(196, 7, 7, 0.7843137255)`,
	"lightGreen": `#b0d04f`,
	"green": `#00ab55`,
	"yellow": `rgba(255, 234, 0, 0.9607843137)`,
	"orange": `#e69603`,
	"container": `styles_container__A2+xb`,
	"titleIcon": `styles_titleIcon__0SKqP`,
	"table": `styles_table__QO19C`,
	"buttonContainer": `styles_buttonContainer__lQzKz`
};
export default ___CSS_LOADER_EXPORT___;
