const SERVICES = {
  indexes: '/indexes',
  index: '/index',
  parameters: '/parameters',
  nodes: '/nodes',
  dataTypes: '/dataTypes',
  customEnums: '/customEnums',
  establishments: '/establishments',
  paddocks: '/paddocks',
  measurements: '/measurements',
  potentialVegetation: '/potentialVegetation',
  customValues: '/customValues',
  mocked: {
    // indexes: '/a6568528-0c01-46c7-9992-37acbb462016',
    indexes: '/7676082f-a601-41a7-8384-48fda6c94fb3', // 401 Response
    index: '/a0e40e81-9b07-4ce2-ba66-3719c974c32b',
    parameters: '/4b8c9701-c7f9-40c2-bed5-2084da1d61bf',
    dataTypes: '/1f6d905c-75e6-4016-b2f4-d3625f14bad4',
    nodes: '/32fed84c-f74e-4f5e-8fd5-84dbf1ed0c7a',
    customEnums: '/2b136f3f-eee6-4665-95c9-bb202f858f0f',
    measurements: '/4b8963c2-e410-472a-ab0d-834e0d8f82a5',
    measurement: '/e124dcd6-177f-4f8c-8ded-2770d84ec10f	',
    establishments: '/d8783fa3-316f-446b-b6db-683aff207bb9',
  },
};

export default SERVICES;
