import React from 'react';
import { Card, Divider, Chip, Button } from '@mui/material';
import { string, shape, func } from 'prop-types';

import styles from './styles.module.scss';

const OptionsCard = ({
  title,
  option1,
  option2,
  labelsClassName = 'p3-regular-10',
  buttonClassName = '',
  cardClassName = '',
  titleClassName = '',
}) => {
  return (
    <Card variant="outlined" className={`${styles.card} ${cardClassName}`}>
      <Divider>
        <Chip label={title} className={`${styles.chip} ${titleClassName}`} />
      </Divider>
      <div className={styles.buttons}>
        <Button
          variant="outlined"
          size="large"
          className={`${styles.button} ${buttonClassName}`}
          onClick={option1.onClick}
        >
          <span className={labelsClassName}>{option1.label}</span>
        </Button>

        {option2 && (
          <Button
            variant="outlined"
            size="large"
            className={`${styles.button} ${buttonClassName}`}
            onClick={option2.onClick}
          >
            <span className={labelsClassName}>{option2.label}</span>
          </Button>
        )}
      </div>
    </Card>
  );
};

OptionsCard.prototypes = {
  title: string,
  option1: shape({
    label: string,
    onClick: func,
  }).isRequired,
  option2: shape({
    label: string,
    onClick: func,
  }),
  labelsClassName: string,
  buttonClassName: string,
  cardClassName: string,
  titleClassName: string,
};

export default OptionsCard;
