import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import pinIcon from '@iconify/icons-eva/pin-fill';
import EditIcon from '@iconify/icons-eva/edit-2-fill';
import ZoomInIcon from '@iconify/icons-eva/maximize-outline';
import Button from '@mui/material/Button';
import { Icon } from '@iconify/react';

import PageTitle from 'components/common/page-title';
import { getEstablishments } from 'api/gets';
import SERVICES from 'constants/services';
import Table from 'components/table';
import ROUTES from 'routes/routesPaths';

import styles from './styles.module.scss';

const HomePage = () => {
  const navigate = useNavigate();

  const { data: establishments, isLoading: isLoadingEstablishments } = useQuery(
    SERVICES.establishments,
    () => getEstablishments()
  );

  const columns = [
    {
      field: 'ver',
      headerName: 'VER',
      width: 20,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const row = params.row;
        const onClick = (e) => {
          e.stopPropagation();
          navigate(`${ROUTES.establishments}/${row.id}${ROUTES.display}`);
        };
        return (
          <div className={styles.buttonContainer}>
            <Button variant="outlined" onClick={onClick}>
              <Icon icon={ZoomInIcon} width={23} height={23} />
            </Button>
          </div>
        );
      },
    },
    {
      field: 'edit',
      headerName: 'EDITAR',
      width: 20,
      flex: 1,
      align: 'left',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const row = params.row;
        const onClick = (e) => {
          e.stopPropagation();
          navigate(`${ROUTES.establishments}/${row.id}${ROUTES.edit}`);
        };
        return (
          <div className={styles.buttonContainer}>
            <Button variant="outlined" onClick={onClick}>
              <Icon icon={EditIcon} width={23} height={23} />
            </Button>
          </div>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 20,
      flex: 4,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'producer',
      headerName: 'Productor',
      width: 20,
      flex: 3,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'departament',
      headerName: 'Departamento',
      width: 20,
      flex: 3,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'location',
      headerName: 'Localidad',
      width: 20,
      flex: 3,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <div className={styles.container}>
      <div>
        <PageTitle
          text="Mis establecimientos"
          titleContainerClassName={styles.title}
          icon={pinIcon}
          iconClassName={styles.titleIcon}
          isCenterText
        />
      </div>
      <Table
        rows={establishments?.establishments}
        columns={columns}
        cardClassName={styles.table}
        loading={isLoadingEstablishments}
      />
    </div>
  );
};

export default HomePage;
