import React from 'react';
import SadFace from '@iconify-icons/la/frown';
import HomeIcon from '@iconify-icons/la/home';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'routes/routesPaths';

import { Icon } from '@iconify/react';
import Logo from 'assets/logo.png';
import { Button } from '@mui/material';

import styles from './styles.module.scss';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <Icon icon={SadFace} height="150" width="150" className={styles.icon} />
      <p className={styles.title}>404 </p>
      <p className={styles.description}>Página no encontrada</p>
      <Button
        variant="contained"
        size="large"
        className={styles.button}
        onClick={() => navigate(ROUTES.root)}
        type="button"
      >
        <Icon icon={HomeIcon} height="30" width="30" className={styles.homeIcon} />
        <span className="h3-bold-18 white">Volver a inicio</span>
      </Button>
      <div className={styles.logoLeft}>
        <img src={Logo} alt="logo" className={styles.logo} />
      </div>
      <div className={styles.logoRight}>
        <img src={Logo} alt="logo" className={styles.logo} />
      </div>
    </div>
  );
};
export default NotFoundPage;
