import React from 'react';

import styles from './styles.module.scss';

const Users = () => {
  return (
    <div className={styles.container}>
      <span className="h1-bold-32">Usuarios</span>
      <div></div>
    </div>
  );
};

export default Users;
