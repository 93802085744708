const ROUTES = {
  root: '/',
  login: '/login',
  indexDetail: '/backoffice/indice',
  editIndex: '/backoffice/editar-indice',
  editEntity: '/editar-entidad',
  createEntity: '/crear-entidad',
  params: '/parametros',
  entities: '/entidades',
  dataTypes: '/datos',
  createDataType: '/crear-dato',
  backoffice: '/backoffice',
  createIndex: '/backoffice/crear-indice',
  users: 'usuarios',
  roles: 'roles',
  notFound: '/404',
  establishments: '/establecimientos',
  paddocks: '/potreros',
  display: '/ver',
  edit: '/editar',
  displayMeasurement: '/ver-medida',
  measurements: '/medidas',
  enums: '/enumerados',
  createEnum: '/crear-enumerado',
  createPaddock: '/crear-potrero',
  expiredSession: '/sesion-expirada',
};

export default ROUTES;
