import axios from 'axios';
import Cookies from 'js-cookie';
import ROUTES from 'routes/routesPaths';

const baseURL =
  process.env.REACT_APP_IS_MOCK === 'true'
    ? process.env.REACT_APP_MOCK_API_URL
    : process.env.REACT_APP_API_URL;

const axiosClient = axios.create({
  baseURL,
});

axiosClient.defaults.headers = {
  Accept: 'application/json',
};

axiosClient.defaults.withCredentials = false;

axiosClient.interceptors.request.use((config) => {
  const session =
    Cookies.get('session') && JSON.parse(Cookies.get('session'), { path: ROUTES.root });
  if (session?.user?.accessToken)
    config.headers['Authorization'] = `Bearer ${session.user.accessToken}`;
  return config;
});

axiosClient.interceptors.response.use(
  function (response) {
    //Dispatch any action on success
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      //Add Logic to
      //1. Redirect to login page or
      //2. Request refresh token
      Cookies.remove('session', { path: ROUTES.root });
      return (window.location.href = ROUTES.expiredSession);
    }
    throw error?.response?.data;
  }
);

export default axiosClient;
